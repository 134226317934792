import services_header from "../../Assets/services_header.jpg";
import services_bg from "../../Assets/services_bg.png";
import air_frieght from "../../Assets/air_freight.jpg";
import ocean_frieght from "../../Assets/ocean_frieght.jpg";
import ground_shipping from "../../Assets/ground_shipping.jpg";
import clearance from "../../Assets/clearance.jpg";
import documentation from "../../Assets/documentation.jpg";
import warehousing from "../../Assets/warehousing.jpg";
import dangerousgoods from "../../Assets/warning_12261070.png";
import PharmaceuticalIdustry from "../../Assets/bottle_6614099.png";
import automative from "../../Assets/car_7573424.png";
import machine from "../../Assets/setting_12248697.png";
import sports from "../../Assets/football-ball_11792549.png";
import food from "../../Assets/cocktail-shaker_16811199.png";
import jewellery from "../../Assets/charm_16859922.png";
import mat from "../../Assets/mat_6143550.png";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import "./OurServices.css";

function OurServices() {
  const pathStyles = {
    transform: 'scalex(2.5)',
  };
  const svgStyles = {
    transform: 'rotate(180deg)',
      width: '100%',
  };

  const ourservicesNew = {
padding: '0 307px'
  }

  const oceanfreightbutton ={
    position: 'relative',
    marginLeft: '-34pc'
  }

 
   
  return (
    <>
      {/* header_img */}
      <div class="relative bg-blue-500 text-white our-services-mukul-div">
        <img
          src={services_header}
          alt="Background"
          class="w-full h-[30rem] object-cover opacity-50"
        />

        <div className="absolute inset-0 flex flex-col items-center justify-center our-services-mukul">
          <h1
            className="text-4xl font-bold"
            
          >
            Our Services
          </h1>
          <p
            className="text-lg mt-2"
           
          >
            Just Say the Word, We Can Do it all!
          </p>
        </div>

        <div className="absolute bottom-0 left-0 right-0" style={svgStyles}>
              <svg style={{marginTop: '-1px'}}
                viewBox="0 0 1440 320"
                preserveAspectRatio="none"
                className="w-full"
              >
                <path style={pathStyles}
                  fill="#fff" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
                  ></path>
              </svg>
            </div>
      </div>

      {/* servcies list */}
      <section className="ourservices-section">
        <div className="mt-20 p-10 relative our-services_new our-services_new-M" style={ourservicesNew}>
          <div className="absolute inset-0 z-[-1]">
            <img
              className="w-full h-full object-cover"
              src={services_bg}
              alt="Services Header"
            />
          </div>
          <div className="relative flex flex-col lg:flex-row items-center lg:justify-between px-4 lg:px-8 py-6 bg-opacity-60 bg-white section-our-services_new" style={{zIndex: '-2'}}>
            <div className="flex-shrink-0 mb-6 lg:mb-0 lg:w-2/5 max-w-full overflow-hidden">
              <img
                src={air_frieght}
                className="w-full h-auto rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl rounded-br-[250px]"
                alt="Air Freight" style={{borderRadius: '100px 100px 300px 100px'}}
              />
            </div>

            <div className="lg:w-3/5 lg:pl-20 flex flex-col items-start justify-center">
              <h3 className="text-xl text-primary-blue font-montserrat mb-2">
                The Sky's The Limit
              </h3>
              <h1 className="text-secondary-blue text-5xl py-2 font-montserrat font-semibold mb-4">
                Air Freight
              </h1>
              <p className="text-custom-grey text-lg leading-relaxed mb-4 text-left">
                DBB provides air freight forwarding services for both domestic
                and international. We understand the value of your time, money
                and most importantly your decision of choosing us and thus aim
                to provide a better quality service with reasonable costing.
                Sending shipments through air is like adding wings to it.
                Sometimes there are shipments that need to be shipped as quickly
                as possible then air freight is the quickest way of shipping
                goods.
              </p>
              <p className="text-custom-grey text-lg leading-relaxed mb-4 text-left">
                We take our client’s repute as our own and thus serve them by
                ensuring timely shipment of time-bound commitments. No matter
                what your timeline and budget is, we always have a freighting
                option for you. Faster, secured and timely transits are always
                made through DBB because we have motives beyond making money.
              </p>
              <p className="text-custom-grey text-lg leading-relaxed mb-4 text-left">
                We can collect your goods from anywhere and safely transport
                them to the desired destination. We ask our clients to pack
                their goods in such a way so that it is easy for us to collect.
                We can also provide packaging materials if required. As an
                expert in the field of air transportation service, we manage
                your shipment from door to destination. Our team can handle your
                documentation, supervise the entire process, and manage ground
                transportation to ensure that everything is done within the time
                limit.
              </p>
              <a
                href="#quote"
                className="px-8 no-underline border-2 border-primary-blue focus:ring-secondary-blue bg-blue-600  text-white rounded-full py-3 font-semibold text-lg  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300"
              >
                Get Quote
              </a>
              <button></button>
            </div>
          </div>
        </div>

        <div className="mt-20 p-10 relative our-services_new-section-tow-M" style={ourservicesNew}>
          <div className="absolute inset-0 z-[-1]">
            <img
              className="w-full h-full object-cover"
              src={services_bg}
              alt="Services Header"
            />
          </div>
          <div className="relative flex flex-col lg:flex-row items-center lg:justify-between px-4 lg:px-8 py-6 bg-opacity-60 bg-white" style={{zIndex: '-2'}}>
            <div className="lg:w-3/5  flex flex-col items-start justify-center">
              <h3 className="text-xl text-primary-blue font-montserrat mb-2">
                Ocean of Opportunities
              </h3>
              <h1 className="text-secondary-blue text-5xl py-2 font-montserrat font-semibold mb-4">
                Ocean Freight
              </h1>
              <p className="text-custom-grey text-lg leading-relaxed mb-4 text-left">
                When you can afford to wait for a while and are not bound with
                deadlines, Sea freight is something you can consider. Sea
                freight is the most pocket friendly mode of shipment comparative
                to Air and Road shipping, but takes a little more time to reach
                on destination. Though our specialized team works constantly to
                reduce transit time and deliver the shipment a fast as possible.
              </p>
              <p className="text-custom-grey text-lg leading-relaxed mb-4 text-left">
                We offer:- Full container load (FCL) and less than container
                load (LCL) Bulk container shipping
              </p>
            </div>
            <div className="flex-shrink-0 lg:pl-5 mb-6 lg:mb-0 lg:w-2/5 max-w-full overflow-hidden">
              <img
                src={ocean_frieght}
                className="w-full h-auto rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl rounded-br-[250px]"
                alt="Air Freight"
              />
            </div>
          </div>
          <a   style={oceanfreightbutton}
            href="#quote"
            className="border-2 no-underline border-primary-blue focus:ring-secondary-blue bg-blue-600 absolute left-[55px] text-white rounded-full px-6 py-3 font-semibold text-lg  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300 oceanfreight-an-M"
          >
            Get Quote
          </a>
        </div>

        <div className="mt-20 p-10 relative our-services_new-section-three-M" style={ourservicesNew}>
          <div className="absolute inset-0 z-[-1]">
            <img
              className="w-full h-full object-cover"
              src={services_bg}
              alt="Services Header"
            />
          </div>
          <div className="relative flex flex-col lg:flex-row items-center lg:justify-between px-4 lg:px-8 py-6 bg-opacity-60 bg-white" style={{zIndex: '-2'}}>
            <div className="flex-shrink-0 mb-6 lg:mb-0 lg:w-2/5 max-w-full overflow-hidden">
              <img
                src={ground_shipping}
                className="w-full h-auto rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl rounded-br-[250px]"
                alt="Air Freight"
              />
            </div>

            <div className="lg:w-3/5 lg:pl-20 flex flex-col items-start justify-center">
              <h3 className="text-xl text-primary-blue font-montserrat mb-2">
                Unending Roads
              </h3>
              <h1 className="text-secondary-blue text-5xl py-2 font-montserrat font-semibold mb-4">
                Ground shipping
              </h1>
              <p className="text-custom-grey text-lg leading-relaxed mb-4 text-left">
                Ground shipping constitutes the backbone of transportation
                creating a wide network of transit chain linking railways,
                roadways, airports and seaports. Whether you wish to send your
                shipment across the town/country or around the world ground
                shipping comes into play. We at DBB ensure that there is always
                an appropriate vehicle available with every necessary
                equipment’s required for your freight. We can pick up your goods
                from anywhere and safely transport them at the desired
                destination. Our team is always extra careful while handling
                your goods
              </p>
              <a
                href="#quote"
                className="px-8 no-underline border-2 border-primary-blue focus:ring-secondary-blue bg-blue-600 text-white rounded-full py-3 font-semibold text-lg  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300"
              >
                Get Quote
              </a>
            </div>
          </div>
        </div>

        <div className="mt-20 p-10 relative our-services_new-section-four-M" style={ourservicesNew}>
          <div className="absolute inset-0 z-[-1]">
            <img
              className="w-full h-full object-cover"
              src={services_bg}
              alt="Services Header"
            />
          </div>
          <div className="relative flex flex-col lg:flex-row items-center lg:justify-between px-4 lg:px-8 py-6 bg-opacity-60 bg-white" style={{zIndex: '-2'}}>
            <div className="lg:w-3/5  flex flex-col items-start justify-center">
              <h3 className="text-xl text-primary-blue font-montserrat mb-2">
                Your stock Is Yours
              </h3>
              <h1 className="text-secondary-blue text-5xl py-2 font-montserrat font-semibold mb-4">
                Clearance
              </h1>
              <p className="text-custom-grey text-lg leading-relaxed mb-4 text-left">
                Trading in global market is more of a challenge and all the
                businesses must abide by the laws and regulations of Global
                trade. Maintaining compliance with guidelines and rules of
                International trade is of utmost importance for national and
                international security. To combat these challenges our expertise
                DBB team work relentlessly. With experience of custom clearance
                and great knowledge of these rules and regulations, DBB provides
                safe and reliable services.
              </p>
              <p className="text-custom-grey text-lg leading-relaxed mb-4 text-left">
                All the items imported and exported have to be custom cleared
                .Custom clearance procedure involves submission of required
                documents. This applies to items brought in as personal effects
                and also imported by trade and business establishments including
                governmental agencies. Necessary duties have to be paid before
                the goods are released by customs.
              </p>
            </div>
            <div className="flex-shrink-0 lg:pl-5 mb-6 lg:mb-0 lg:w-2/5 max-w-full overflow-hidden">
              <img
                src={clearance}
                className="w-full h-auto rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl rounded-br-[250px]"
                alt="Air Freight"
              />
            </div>
          </div>
          <a style={oceanfreightbutton}
            href="#quote"
            className="border-2 no-underline border-primary-blue focus:ring-secondary-blue bg-blue-600 absolute left-[55px] text-white rounded-full px-6 py-3 font-semibold text-lg  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300 oceanfreight-an-M"
          >
            Get Quote
          </a>
        </div>

        <div className="mt-20 p-10 relative our-services_new-section-five-M" style={ourservicesNew}>
          <div className="absolute inset-0 z-[-1]">
            <img
              className="w-full h-full object-cover"
              src={services_bg}
              alt="Services Header"
            />
          </div>
          <div className="relative flex flex-col lg:flex-row items-center lg:justify-between px-4 lg:px-8 py-6 bg-opacity-60 bg-white" style={{zIndex: '-2'}}>
            <div className="flex-shrink-0 mb-6 lg:mb-0 lg:w-2/5 max-w-full overflow-hidden">
              <img
                src={documentation}
                className="w-full h-auto rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl rounded-br-[250px]"
                alt="Air Freight"
              />
            </div>

            <div className="lg:w-3/5 lg:pl-20 flex flex-col items-start justify-center">
              <h3 className="text-xl text-primary-blue font-montserrat mb-2">
                Guide to Success
              </h3>
              <h1 className="text-secondary-blue text-5xl py-2 font-montserrat font-semibold mb-4">
                Documentation
              </h1>
              <p className="text-custom-grey text-lg leading-relaxed mb-4 text-left">
                DBB expertise lies in the fact that we can provide an individual
                solution for heavy loads worldwide. The complexity of these
                shipments include requirement of specialist equipment’s of
                transportation from origin to destination.
              </p>
              <p className="text-custom-grey text-lg leading-relaxed mb-4 text-left">
                Our continuous efforts to provide better services has led DBB to
                establish a rigorous team of professionals who manage and
                monitor logistics planning for complex and heavy projects
                throughout the world. DBB has local contacts and alliance with
                team of expert professionals, providing infrastructure and
                special equipment’s which allow us to handle shipments with
                customized solutions guaranteeing complete satisfaction of
                clients. DBB ensures that all collaborating partners work in a
                coordinating manner to deliver better results
              </p>
              <a
                href="#quote"
                className="px-8 no-underline border-2 border-primary-blue focus:ring-secondary-blue bg-blue-600 text-white rounded-full py-3 font-semibold text-lg  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300"
              >
                Get Quote
              </a>
            </div>
          </div>
        </div>

        <div className="mt-20 p-10  our-services_new-section-six-M" style={ourservicesNew}>
          <div className="absolute inset-0 z-[-1]">
            <img
              className="w-full h-full object-cover"
              src={services_bg}
              alt="Services Header"
            />
          </div>
          <div className="relative flex flex-col lg:flex-row items-center lg:justify-between px-4 lg:px-8 py-6 bg-opacity-60 bg-white" style={{zIndex: '-2'}}>
            <div className="lg:w-3/5  flex flex-col items-start justify-center">
              <h3 className="text-xl text-primary-blue font-montserrat mb-2">
                Space to Fit Everything
              </h3>
              <h1 className="text-secondary-blue text-5xl py-2 font-montserrat font-semibold mb-4">
                Warehousing
              </h1>
              <p className="text-custom-grey text-lg leading-relaxed mb-4 text-left">
                Our company provides a wide range of warehousing options for
                both short term and long term storage. While serving almost
                every industry, we always make sure that there is a warehousing
                option suitable for the specific needs of the goods /products.
                Our warehouse service ensures greater security and handling of
                goods on account of latest mechanical devices used in handling
                and preserving the goods. We provide you with best of
                warehousing solutions using best tools, processes and system,
                enabling you to fulfill your business commitments to your
                customers throughout the world.
              </p>
              <p className="text-custom-grey text-lg leading-relaxed mb-4 text-left">
                Our Services Include Receiving and issuing of supplies.(pick,
                pack, lift). Quality control and verification. Storage of goods.
                Documentation flow. Special measures to protect goods from loss,
                damage, environmental impacts ensuring better security and
                protection.
              </p>
            </div>
            <div className="flex-shrink-0 lg:pl-5 mb-6 lg:mb-0 lg:w-2/5 max-w-full overflow-hidden">
              <img
                src={warehousing}
                className="w-full h-auto rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl rounded-br-[250px]"
                alt="Air Freight"
              />
            </div>
          </div>
          <a style={oceanfreightbutton}
            href="#quote"
            className="border-2 no-underline border-primary-blue focus:ring-secondary-blue bg-blue-600 absolute left-[55px] text-white rounded-full px-6 py-3 font-semibold text-lg  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300 oceanfreight-an-M"
          >
            Get Quote
          </a>
        </div>
      </section>

      {/* industries we serve */}
      <section className="our-services_new-section-seven-M" style={ourservicesNew}>
        <h1 className="text-secondary-blue font-semibold text-5xl text-center mt-20 mb-8">
          Industries We Serve
        </h1>
        <div className="px-4 py-8">
          <div className="flex flex-col lg:flex-row lg:gap-8 lg:justify-between">
            <div className="flex  flex-col items-center bg-white shadow-md rounded-bl-[100px] p-6 max-w-md mx-auto">
              <img
                src={dangerousgoods}
                alt="Air Freight"
                className="w-20 h-20 object-cover rounded-t-lg mb-4"
              />
              <h2 className="text-2xl text-primary-blue font-semibold mb-4">
                Dangerous Goods
              </h2>
              <p className="text-custom-grey text-lg leading-relaxed text-center">
                Transporting dangerous goods is a complex procedure and requires
                detailed understanding of the relevant regulations. The emphasis
                of the company is to provide complete dangerous goods logistics
                service to the customer. This is why DBB offers a wide variety
                of services that helps support and reassures our customers with
                all aspects of handling dangerous goods.
              </p>
            </div>
            <div className="flex flex-col rounded-bl-[100px]  items-center bg-white shadow-md rounded-lg p-6 max-w-md mx-auto">
              <img
                src={PharmaceuticalIdustry}
                alt="Air Freight"
                className="w-20 h-20 object-cover rounded-t-lg mb-4"
              />
              <h2 className="text-2xl text-primary-blue font-semibold mb-4">
                Pharmaceutical industry
              </h2>
              <p className="text-custom-grey text-lg leading-relaxed text-center">
                Agility’s solutions meet the unique requirements of the
                pharmaceutical industry by providing a range of services,
                including storage in multiple temperature zones, cold-chain
                solutions, reverse logistics and advanced tracking and tracking
                technologies, which ensure total supply chain visibility, and
                reporting on merchandise flows across all modes of transport.
              </p>
            </div>
            <div className="flex rounded-bl-[100px]  flex-col items-center bg-white shadow-md rounded-lg p-6 max-w-md mx-auto">
              <img
                src={automative}
                alt="Air Freight"
                className="w-20 h-20 object-cover rounded-t-lg mb-4"
              />
              <h2 className="text-2xl text-primary-blue font-semibold mb-4">
                Automotive industry
              </h2>
              <p className="text-custom-grey text-lg leading-relaxed text-center">
                With DBB transportation of automobiles especially two wheelers
                and four wheelers, trucks, and commercial vehicles has been made
                easy and simple throughout the Globe as it takes a lot of
                experience and expertise in which we excel.
              </p>
            </div>
          </div>
        </div>

        <div className="relative px-4 py-8">
          <div className="flex flex-col gap-8 lg:gap-8">
            <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-8">
              <div className="flex flex-col items-center bg-white shadow-md rounded-bl-[100px] p-6 max-w-md mx-auto lg:w-1/2">
                <img
                  src={machine}
                  alt="Air Freight"
                  className="w-20 h-20 object-cover rounded-t-lg mb-4"
                />
                <h2 className="text-2xl text-primary-blue font-semibold mb-4">
                  {" "}
                  Machinery
                </h2>
                <p className="text-custom-grey text-lg leading-relaxed text-center">
                  We specialize in shipping all types of machinery to any part
                  of the world. We provide safe and secure transport of aircraft
                  engines, landing gears, fuselages and a wide variety of other
                  aerospace and aeronautical components.
                </p>
              </div>
              <div className="flex flex-col items-center bg-white shadow-md rounded-bl-[100px] p-6 max-w-md mx-auto lg:w-1/2">
                <img
                  src={sports}
                  alt="Air Freight"
                  className="w-20 h-20 object-cover rounded-t-lg mb-4"
                />
                <h2 className="text-2xl text-primary-blue font-semibold mb-4">
                  {" "}
                  Sports Goods
                </h2>
                <p className="text-custom-grey text-lg leading-relaxed text-center">
                  The sports goods industry is growing and so does its demand.
                  Be it inflatable balls, cricket bat and leg pads, rugby balls,
                  sports nets and gymnasium and athletic equipment’s; everything
                  can be moved with greatest ease with DBB..
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-8">
          <div className="flex flex-col gap-8 lg:flex-row lg:gap-8 lg:justify-between">
            <div className="flex  flex-col items-center bg-white shadow-md rounded-bl-[100px] p-6 max-w-md mx-auto">
              <img
                src={food}
                alt="Air Freight"
                className="w-20 h-20 object-cover rounded-t-lg mb-4"
              />
              <h2 className="text-2xl text-primary-blue font-semibold mb-4">
                Foods
              </h2>
              <p className="text-custom-grey text-lg leading-relaxed text-center">
                Perishable goods (like fruits, vegetables, meat, dairy products)
                are generally those items which gets spoiled over time under
                exposure to temperature changes and other environmental aspects
                such as altitude, heat, moisture etc. Such products need to be
                handled and stored with specific procedure.
              </p>
            </div>
            <div className="flex flex-col rounded-bl-[100px]  items-center bg-white shadow-md rounded-lg p-6 max-w-md mx-auto">
              <img
                src={jewellery}
                alt="Air Freight"
                className="w-20 h-20 object-cover rounded-t-lg mb-4"
              />
              <h2 className="text-2xl text-primary-blue font-semibold mb-4">
                Jewellery And Handicraft Industry
              </h2>
              <p className="text-custom-grey text-lg leading-relaxed text-center">
                The flow of culture and traditions all around the globe
                facilitate people to share their ideas. DBB let people share
                those ideas and expand their horizons by making g the shipping
                easy and simple. From fashion jewelry to fashion
              </p>
            </div>
            <div className="flex rounded-bl-[100px]  flex-col items-center bg-white shadow-md rounded-lg p-6 max-w-md mx-auto">
              <img
                src={mat}
                alt="Air Freight"
                className="w-20 h-20 object-cover rounded-t-lg mb-4"
              />
              <h2 className="text-2xl text-primary-blue font-semibold mb-4">
                {" "}
                Textile Industry
              </h2>
              <p className="text-custom-grey text-lg leading-relaxed text-center">
                Considered as one of the most important sector of an economy,
                the production and consumption of textiles is an increasingly
                global affair and keeping in mind the growing demand of textiles
                we offer the most convenient .
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurServices;
