import React from "react";
import warehouse from "../../Assets/whatware.jpg";
import Dangerous from "../../Assets/dangerous.jpg";
import "./Dangerousgoods.css";

const Dangerousgoods = () => {
  return (
    <div>
      <div className="Ground-Shipping-Blog-div">
        <div className="Ground-Shipping-Img-div">
          <div className="Ground-shiping-img-div">  <img src={Dangerous}  alt="MukulDbb"  style={{width: '825px', height: '510px'}} /></div>
          <div className="Ground-shipaing-all-content">

          <h1>How to Transport Dangerous Goods?</h1>
          <h3>What are dangerous goods?</h3>
          <p>
            Dangerous Goods are items that may endanger the safety of an
            aircraft or persons on board the aircraft. Dangerous Goods are also
            known as restricted articles, hazardous materials and dangerous
            cargo. Many common items found in your household can be considered
            dangerous goods for air transport
          </p>
          <p>
            The International Civil Aviation Organization (ICAO) or the local
            Civil Aviation Authority Regulations govern their carriage onboard
            aircraft.
          </p>

          <h3
            style={{
              fontSize: "22px",
              color: "#4444444",
              marginBottom: "2rem",
            }}
          >
            Setting the standards leads to safety
          </h3>
          <p>
            Some items may endanger the safety of an aircraft or persons on
            board, and these dangerous materials can either be forbidden or
            restricted for air transport. IATA leads industry efforts to ensure
            the safe transport of dangerous goods by air. We provide a great
            variety of technical knowledge, products, services and training
            solutions, tailored to satisfy industry needs.
          </p>

          <p>
            The development of standards for documentation, handling and
            training, as well as the promotion and use, contributed to achieving
            a very high degree of safety in the transport of dangerous goods by
            air.
          </p>

          <h3
            style={{
              text: "bold",
              fontSize: "22px",
              marginBottom: "2rem",
              fontWeight: "600",
            }}
          >
            Dangerous Goods Regulations
          </h3>
          <p>
            IATA works closely with local governments and ICAO in the
            development of regulations. This way, it is ensured that the rules
            and guidelines on dangerous goods transport are effective and
            operational practical.
          </p>
          <p>
            The development of standards for documentation, handling and
            training, as well as the promotion and use, contributed to achieving
            a very high degree of safety in the transport of dangerous goods by
            air.
          </p>

          <p>
            The IATA Dangerous Goods Regulations (DGR) manual is the global
            reference for shipping dangerous goods by air and the only standard
            recognized by airlines.
          </p>

          <h3
            style={{
              text: "bold",
              fontSize: "22px",
              marginBottom: "2rem",
              fontWeight: "600",
            }}
          >
            Training
          </h3>

          <p>
            The 63rd edition of the Dangerous Goods Regulations includes the
            provisions on competency-based training and assessment (CBTA) as
            agreed by the ICAO Dangerous Goods Panel in DGP/27 (September 2019).
            There is a two-year transition period and therefore, the training
            provisions from the 61st edition may continue to be used until 31
            December 2022.
          </p>
          <p>
            Appendix I.1.5 as shown in the 61st edition has been adopted in
            Subsection 1.5 and Subsection 1.5 from the 61st edition has been
            moved to Attachment A of Appendix H.
          </p>

          <p>
            The guidance material on the development and implementation of
            competency-based training and assessment can be found in Appendix H.
            This material is the practical guide for the industry players to
            develop their training framework in accordance with the new
            provisions and will continue to be enhanced by the Dangerous Goods
            Training Working Group (DGTWG) based on the feedback from training
            providers and member airlines.
          </p>

          <h3
            style={{
              text: "bold",
              fontSize: "22px",
              marginBottom: "2rem",
              fontWeight: "600",
            }}
          >
            e-DGD Concept
          </h3>

          <p>
            The e-DGD is an electronic approach to manage the IATA DGD,
            leveraging industry initiatives to digitalize data and embrace data
            sharing platform principles.
          </p>

          <p>
            Data is made available on the data sharing platform by the data
            owner and is accessible to whoever requires it depending on the
            roles and responsibilities in the supply chain. The data sharing
            platform principle allows for a close collaboration between all
            stakeholders, including shippers, forwarders, carriers, ground
            handling agents and third party providers.
          </p>

          <p>
            In March 2018, the IATA Cargo Services Conference endorsed the
            principles of data sharing platform for e-DGD with the adoption of a
            policy item.
          </p>

          <h3
            style={{
              text: "bold",
              fontSize: "22px",
              marginBottom: "2rem",
              fontWeight: "600",
            }}
          >
            Benefits
          </h3>
          <p>1. Increased data quality</p>
          <p>2. More transparency and traceability</p>
          <p>3. Reduced number of errors and delays</p>
          <p>4. Leaner and faster DG processes</p>
          <p>5. Improved customer experience</p>

          <h3
            style={{
              text: "bold",
              fontSize: "22px",
              marginBottom: "2rem",
              fontWeight: "600",
            }}
          >
            Satisfying international regulations
          </h3>
          <p>
            To ship dangerous goods, consignors are required to prepare a form
            certifying that the cargo has been packed, labeled and declared
            according with the IATA Dangerous Goods Regulations (DGR). The
            attached sample Shipper’s Declarations form reflect those that have
            been adopted into the 60th edition of the DGR. Forms of the design
            as shown in the 59th edition may continue to be used until 31
            December 2024.
          </p>

          <h3
            style={{
              text: "bold",
              fontSize: "22px",
              marginBottom: "2rem",
              fontWeight: "600",
            }}
          >
            Process to Ship Dangerous Goods
          </h3>

          <h3
            style={{
              text: "bold",
              fontSize: "22px",
              marginBottom: "2rem",
              fontWeight: "600",
            }}
          >
            1. Know the guidelines
          </h3>

          <p>
            It’s essential to follow the guidelines while transporting anything
            named hazardous products.
          </p>

          <p>
            The guidelines contain an assortment of limitations, including
            remembering subtleties for the sorts of bundling, checking, marking,
            and documentation required. Rules can change contingent upon the
            method of transport, so look at your transporter to observe how your
            hazardous merchandise will be shipped.
          </p>

          <h3
            style={{
              text: "bold",
              fontSize: "22px",
              marginBottom: "2rem",
              fontWeight: "600",
            }}
          >
            2. Ensure you’ve received training
          </h3>

          <p>
            Anyone transporting hazardous products needs to have gotten the
            required training(s) and comprehend the pertinent regulation(s).
            Then again, someone else or an organization that has gotten the
            suitable training(s) can be recruited to set up the shipment for
            your benefit.
          </p>

          <h3
            style={{
              text: "bold",
              fontSize: "22px",
              marginBottom: "2rem",
              fontWeight: "600",
            }}
          >
            3. Arrange and pronounce your shipment accurately
          </h3>
          <p>
            The transporter is answerable for guaranteeing that hazardous
            merchandise should be distinguished, grouped, pronounced, pressed,
            stamped, and named with the right documentation for the
            nations/regions of beginning, travel, and objective. Assuming you’re
            uncertain, check with the producer or provider to figure out the
            grouping of your risky products.
          </p>

          <h3
            style={{
              text: "bold",
              fontSize: "22px",
              marginBottom: "2rem",
              fontWeight: "600",
            }}
          >
            4. Pack your shipment appropriately
          </h3>

          <p>
            Much of the time it’s a necessity to utilize affirmed bundling with
            unobscured United Nations detail markings. These markings show that
            the bundling has been tried for specifically required abilities
            expected to ship risky products. See the present IATA DG Regulations
            see whether your shipment requires them.
          </p>

          <h3
            style={{
              text: "bold",
              fontSize: "22px",
              marginBottom: "2rem",
              fontWeight: "600",
            }}
          >
            5. Have the right marking and documentation
          </h3>
          <p>
            Most hazardous merchandise shipments require unobscured marks that
            connect with the risk class and auxiliary peril. Clients can buy
            supported marks (or view a rundown of other name sellers) on the
            IATA hazardous merchandise site. Old marks on reused boxes should be
            eliminated.
          </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dangerousgoods;
