import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./About.css";
import "bootstrap/dist/css/bootstrap.min.css";
import men from "../../Assets/men-1979261_1920.jpg";
import aeroplane from "../../Assets/aeroplane.jpg";
import back from "../../Assets/BG-TEAN-Home.png";
import magic from "../../Assets/magic-box_10420408.png";
import yard from "../../Assets/yard.jpg";
import freight from "../../Assets/delivery-truck_7361876.png";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import location from "../../Assets/location_7234899.png";
import visual from "../../Assets/visual_16753100.png";
import hand from "../../Assets/handshake_11605573.png";

const About = () => {
  const pathStyles = {
    transform: 'scalex(2.5)',
  };
  const svgStyles = {
    transform: 'rotate(180deg)',
      width: '100%',
  };
  const firstcontainer = {
    padding: '120px 91px 21px 140px'
  }
  const secondcontainer = {
    padding: '297px'
  }
  const lineHeight={
      lineHeight: '2.2'
  }
  const aeroplaneimgsM ={
    maxWidth: "65%",
    borderRadius: "80px 120px 264px 80px",
    objectFit: "cover",
    float: 'right'
  }
  return (
    <div>
     

<div className="relative bg-blue-500 text-white about-page-mukul-div">
 
 <img src={men} alt="Background" class="w-full h-[30rem] object-cover opacity-50"/>


 <div className="absolute inset-0 flex flex-col items-center justify-center about-page-mukul-h1" >
   <h1 className="text-4xl font-bold about-h1" 
   >About Us</h1>
   <p className="text-lg mt-2">DBB Worldwide Imagines World As One</p>
 </div>


 {/* <div style={{marginTop:"-269px"}} class="relative -mt-1 w-full ">
   <svg class="w-full h-50px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
   <path style={pathStyles}
                  fill="#fff" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
                  ></path>   </svg>
 </div> */}
 <div className="absolute bottom-0 left-0 right-0" style={svgStyles}>
              <svg style={{marginTop: '-1px'}}
                viewBox="0 0 1440 320"
                preserveAspectRatio="none"
                className="w-full"
              >
                <path style={pathStyles}
                  fill="#fff" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
                  ></path>
              </svg>
            </div>
</div>

      <div className="container-About about-page-M" style={firstcontainer}>
        <div className="row">
          <div className="col-sm">
          <div className="About-first-img-M">
          <img className="aeroplane-imgs"

              style={aeroplaneimgsM
              }
              src={aeroplane} alt="MukulDbb"
            />
          </div>
           
          </div>
          <div className="col-sm colabout-ourcompany">
          <div className="colabout-ourcompany-div">
          <h3>World Beyond Boundaries</h3>
            
            <h1>Our Company</h1>
            
            <p>
              Delivery Beyond Boundaries is a privately owned and independent
              company with Headquarter in Delhi India. DBB is a result of long
              contemplation whose ground laying ideology rest upon convenience,
              reliability, and goodwill. We aim to provide customer friendly
              services all around the globe
            </p>
          </div>
            
          </div>
        </div>
      </div>

      <div className="container-About-right container-About-right-M" style={{ marginTop: "5rem", ...secondcontainer  }}>
        <div className="row">
          <div className="col-sm">
            <h3 style={{textAlign: "justify" ,...lineHeight}}>Supply Chain In Our DNA</h3>
            <h1>Who We Are</h1>
            <p>
              DBB is an association of committed professionals who aim to
              provide world class freight services throughout the World. Our
              company‘s philosophy is simple. We believe that innovation and
              feasibility goes hand in hand. Our relentlessly resourceful team
              has contrived an intricate yet simple solution keeping in mind all
              the positive and negative attributes of shipping goods worldwide.
              Our services are far more feasible, fast and reliable than you can
              imagine. Our team can provide you with the best estimate of time
              and expenses. We guide our customers throughout the process of
              freight forwarding and help you choose the best suitable option
              for your freight.
            </p>
          </div>

          <div className="col-sm">
            <img
              style={{
                maxWidth: "100%",
                borderRadius: "80px 120px 264px 80px",
                
              }}
              src={yard} alt="MukulDbb"
            />
          </div>
        </div>
      </div>

      <div className="about-box about-box-movile-M">
        <div className="about-box-inner">
          <img src={magic} alt="MukulDbb" />
          <h2>Out Of The Box Solution</h2>
          <p>
            Our main aim is to connect our clients with their customers living
            in far-flung remote location and connecting the world as one beyond
            boundaries.
          </p>
        </div>
        <div className="about-box-inner">
          <img src={freight} />
          <h2>Best freight services</h2>
          <p>
            Timely delivery, accuracy and complete visibility on all aspects of
            the supply chain is our main logistic attributes. In case of any
            hindrance we take and suggest prompt action for our customers.
          </p>
        </div>
        <div className="about-box-no">
          <h3></h3>
        </div>
      </div>

      <div className="about-box about-box-movile-M">
        <div className="about-box-inner">
          <img src={location} />
          <h2>Out Of The Box Solution</h2>
          <p>
            Our main aim is to connect our clients with their customers living
            in far-flung remote location and connecting the world as one beyond
            boundaries.
          </p>
        </div>
        <div className="about-box-inner">
          <img src={visual} />
          <h2>Best freight services</h2>
          <p>
            Timely delivery, accuracy and complete visibility on all aspects of
            the supply chain is our main logistic attributes. In case of any
            hindrance we take and suggest prompt action for our customers.
          </p>
        </div>
        <div className="about-box-text">
          <h3>Your Dream. Our Mission</h3>
          <h1>We Believe In Hard Work And Dedication</h1>
          <p>
            We strive to provide 360-degree chain solutions to our clients. Some
            of our attributes make us who we are.
          </p>
        </div>
      </div>

      <div className="about-box-last about-box-last-movile-M">
        <div className="aford">
          <img src={hand} />
          <div className="aford-text">
            <h3>Affordability</h3>
            <p>
              Lorem ipsum dolor sit rot off gamet, cons ctetur adipi scing elit.
              Proin rutrum euismod dolor, ultri Lies aliq luam ekolor.
            </p>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </div>
  );
};

export default About;
