import React from "react";
import warehouse from "../../Assets/whatware.jpg";

const Warehousing = () => {
  return (
    <div>
      <div className="Ground-Shipping-Blog-div">
        <div className="Ground-Shipping-Img-div">
          <div className="Ground-shiping-img-div">  <img src={warehouse}  alt="MukulDbb"  style={{width: '825px', height: '510px'}} /></div>
          <div className="Ground-shipaing-all-content">

          <h1>Freight Forwarding Warehousing and How Does it Work?</h1>
          <p>
            Warehouses are an essential component in the chain of distribution
            logistics. A warehouse is any building where products, materials, or
            raw items are stored. Goods need to be stored and treated in a
            proper manner, which is where a warehouse comes in. The term
            “warehousing” refers to receiving and storing finished goods until
            they are shipped or sold to their final destination. It plays a
            crucial role in organizational logistics, as storage is a
            cornerstone of supply chain management.Once a product or good is
            manufactured, it needs to be stored; product cannot be left in the
            open exposed to the elements, nor can it be shipped out immediately.
            Transportation and warehousing are the backbone of a supply chain,
            making sure the acquisition, movement, and distribution of goods are
            conducted in a seamless manner.
          </p>

          <h3 style={{ text: "bold", fontSize: "20px", color: "#4444444" }}>
            Importance of a Warehouse
          </h3>
          <p>
            The main function of a warehouse is to store large quantities of
            goods, condense them into smaller quantities, and dispense them to
            the end customer. The chain of operations of a warehouse is
            receiving, putting away, storage, order preparation, and packing and
            shipping of goods.
          </p>

          <ul>
            <li>
              Allows the storage of all fashion of goods; raw, semi-finished,
              finished, seasonal goods, etc.
            </li>
            <li>
              Reduces the risk of a product or good being damaged during
              storage.
            </li>
            <li>
              Runs inventory management (processing an inventory from entry to
              exit)
            </li>
            <li>
              Makes the distribution network more efficient. -Allows the
              branding and grading of goods.
            </li>
            <li>
              Allows the storage of ordinarily perishable goods such as fruits,
              vegetables, and meat.
            </li>
            <li>
              Protect goods from poor environmental conditions; during
              disasters, for instance.
            </li>
          </ul>
          <br />

          <h3 style={{ text: "bold", fontSize: "20px", color: "#4444444" }}>
          Types of Warehouses
          </h3>

          <p>
            The main function of a warehouse is to store large quantities of
            goods, condense them into smaller quantities, and dispense them to
            the end customer. The chain of operations of a warehouse is
            receiving, putting away, storage, order preparation, and packing and
            shipping of goods
          </p>
          <br />
          <p>
            {" "}
            The next thing to look out for is paperwork. Customs agents are
            extremely cautious when it comes to inspecting and verifying your
            paperwork. Your paperwork and documentation should be error- free
            with all necessary details mentioned, such as the content of the
            shipment and your business details. Here’s a list of the basic
            documents you should keep with you to ensure a hassle-free shipment:
          </p>
          <ul>
            <li><span style={{text:"bold", fontSize:"18px"}}>Receiving: </span>This starts with the warehouse being notified about the arrival of goods. This helps it plan the unloading and ensure the warehouse team can manage other processes in the warehouse accordingly.</li>
            <li><span style={{text:"bold", fontSize:"18px"}}>Putting Away:  </span>Goods must be stored, but before that one has to decide a proper location for them. This process involves storage management of a secondary inventory where the product will eventually go.</li>
            <li><span style={{text:"bold", fontSize:"18px"}}>Storage:   </span>Goods must be stored, but before that one has to decide a proper location for them. This process involves storage management of a secondary inventory where the product will eventually go.</li>
            <li><span style={{text:"bold", fontSize:"18px"}}>Order Preparation:   </span>Also known as order picking, it is the procedure of collecting and preparing items before shipment to a consumer. During order preparation, it is essential for a warehouse to provide the necessary documentation and records pertaining to the shipment.</li>
            <li><span style={{text:"bold", fontSize:"18px"}}>Packing and Shipping:   </span> Every customer order must be managed individually; due to this, packing is a labor-intensive process. On top of this, each order must be accurate and complete with regards to the needs of the customer. Shipping is a key aspect in warehousing, and is the final step of the process. Packing and shipping involves weighing of goods, maintaining shipment information, and labelling the final destination address.</li>
          </ul>
          <h3 style={{ text: "bold", fontSize: "20px", color: "#4444444" }}>
            Warehousing Operations
          </h3>
          <ul>
            <li><span style={{text:"bold", fontSize:"18px"}}>Private Warehouses:</span>Warehouses which are used by producers, traders, or manufacturers to store their own stock exclusively are known as private warehouses. These are typically located near a manufacturing facility and are maintained by individuals from the same facility. An example is a farmer building a warehouse near his field.</li>
            <li><span style={{text:"bold", fontSize:"18px"}}>Public Warehouses:  </span>Warehouses which provide warehousing services and are available to rent by the public are known as public warehouses. These are usually used for the storage of smaller shipments, and are used when dealing with seasonal goods or when entering a new market.</li>
            <li><span style={{text:"bold", fontSize:"18px"}}>Contract Warehouses:  </span>Warehouses which provide warehousing services on a contractual basis are known as contract warehouses. These contracts are generally long term between the two parties (leaser and contractor) and are mostly used when a business is newly established, or when a business is expanding and entering a new market. Such warehouses minimize liability and personal risk, as well as saving a pretty penny.</li>

          </ul>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Warehousing;
