import React from "react";
import Custom from "../../Assets/custom-big.jpg";
import "./CustomCLearance.css";

const CustomCLerance = () => {
  return (
    <div>
      <div className="Ground-Shipping-Blog-div">
        <div className="Ground-Shipping-Img-div">
        <div className="Ground-shiping-img-div">  <img src={Custom} alt="MukulDbb"  style={{width: '825px', height: '510px'}} /></div>
        <div className="Ground-shipaing-all-content">

          <h1>Freight Forwarding Customs Clearance and How Does it Work?</h1>
          <p>
            To understand customs clearance in freight forwarding, it is
            important to understand freight forwarding first. Most importers and
            exporters use freight forwarding to arrange and manage freight
            shipments. The people who conduct freight forwarding are known as
            freight forwarders or freight-forwarding agents; they are
            well-versed in the workings of the shipping process and can get it
            done for you. They almost function as a travel agency does, but for
            freight instead of people.
          </p>

          <ul>
            <li>
              Makes all the bookings, ensures that all paperwork is put together
              and complete, takes care of payments for each step of the shipping
              process.
            </li>
            <li>
              Co-operate and coordinate with others who are involved in the
              shipping process, such as air cargo carriers, truck delivery
              companies, or any logistics provider.
            </li>
            <li>
              Provide support with all issues related to the shipment, if any
              happen to crop up.
            </li>
          </ul>
          <br />
          <p>
            Customs clearance is an important part of the freight forwarding
            process. Any goods shipped internationally have to pass through
            customs check before being allowed into or out of a country. Once
            customs clearance and checks are completed, the freight forwarder
            pays the customs duties and receives a document that functions as a
            receipt for the transaction. Usually, the customs clearance
            procedure is managed by the freight forwarder, but a customs broker
            can be hired if you want to.
          </p>

          <p>
            The preparation of a shipment is crucial, as customs holds the
            authority to confiscate or hold your goods until further notice if a
            shipment is prepared poorly. To ensure a smooth passage through
            customs, a few pre-requisites must be met. First is the preparation
            of a shipping container. If a container is loaded poorly or
            incorrectly, the customs agent overlooking the process will red-flag
            it immediately. Utilize all the space available and make sure all
            cargo is stowed away correctly, otherwise, customs will deny your
            container the necessary clearance.
          </p>
          <br />
          <p>
            {" "}
            The next thing to look out for is paperwork. Customs agents are
            extremely cautious when it comes to inspecting and verifying your
            paperwork. Your paperwork and documentation should be error- free
            with all necessary details mentioned, such as the content of the
            shipment and your business details. Here’s a list of the basic
            documents you should keep with you to ensure a hassle-free shipment:
          </p>
          <ul>
            <li>Shipping quote</li>
            <li>Origin certificate
            </li>
            <li>Material safety data sheet</li>
            <li>Commercial invoice</li>
            <li>Booking confirmation</li>
            <li>Shipper’s letter of instruction</li>
            <li>Booking confirmation</li>
            <li>Bill of loading</li>
            <li>Packing list</li>
            <li>Letter of credit</li>
          </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCLerance;
