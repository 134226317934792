import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import videobutton from "../../Assets/play-button-icon-png-18912.png";
import thumbnail from "../../Assets/DBB Logo (655x358 px)-01.svg";
// import img from "../..";
// import Carousel from "react-elastic-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

import manwithscope from "../../Assets/manwithscope.png";
import philosophy from "../../Assets/philosophy.png";
import Visson from "../../Assets/mission.png";
import AirFreight from "../../Assets/air-freight.png";
import SeaFreight from "../../Assets/ship.png";
import GroundShipping from "../../Assets/ground-shipping.png";
import warehousing from "../../Assets/warehousing.png";
import custom from "../../Assets/customs.png";
import documen from "../../Assets/documentation.png";
import process from "../../Assets/process-01-1024x1024.png";
import customer from "../../Assets/testimonial_8898728.png";
import ball from "../../Assets/ball.jpg";
import factory from "../../Assets/factory.jpg";
import Doc from "../../Assets/doctor.jpg";
import heart from "../../Assets/heart.jpg";
import Straw from "../../Assets/strawbeery.jpg";
import man from "../../Assets/man.jpg";
import jewel from "../../Assets/jewel.jpg";
import Astro from "../../Assets/astro.jpg";
import faisal from "../../Assets/faisal-sir.jpg";
import Amir from "../../Assets/Aamir.jpg";
import BGTean from "../../Assets/BG-TEAN-Home.png";
import airoplane from "../../Assets/airoplane.png";
import sea from "../../Assets/sea.png";
import anchor from "../../Assets/anchor_1723214.png";
import container from "../../Assets/container_13801620.png";
import right from "../../Assets/arrow.png";
import AirIndia from "../../Assets/air-india.png";
import AIrCanada from "../../Assets/air-canada.png";
import singapore from "../../Assets/singapore-airlines.png";
import qatar from "../../Assets/qatar.png";
import swis from "../../Assets/swiss-air.png";
import virgin from "../../Assets/virgin-atlantic.png";
import lufthansa from "../../Assets/lufthansa.png";
import logic from "../../Assets/logisctics-image-2.jpg";
import competitive from "../../Assets/competitive.png";
import trusted from "../../Assets/trusted.png";
import shop from "../../Assets/shopping-cart_2430757.png";
import flightland from "../../Assets/flight_land.mp4";
import { Carousel } from "react-responsive-carousel";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

const Home = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(true);

  const videoRef = useRef(null);

  const breakPoints = [
    { width: 1, itemToShow: 1 },
    { width: 550, itemToShow: 2 },
    { width: 768, itemToShow: 3 },
    { width: 1200, itemToShow: 4 },
  ];

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  const flags = [
    virgin,
    singapore,
    AirIndia,
    lufthansa,
    virgin,
    swis,
    qatar,
    singapore,
    AIrCanada,
  ];
  const logos = [
    { src: heart, alt: "heart", text: "Textile" },
    { src: jewel, alt: "jewel", text: "Jewellery and Handicraft" },
    { src: Doc, alt: "Doc", text: "Pharmaceutical" },
    { src: man, alt: "man", text: "Documentation" },
    { src: Astro, alt: "Astro", text: "Dangerous Goods" },
    { src: factory, alt: "factory", text: "Automobile" },
    { src: ball, alt: "ball", text: "Sports" },
    { src: Straw, alt: "Straw", text: "Food" },
  ];

  useEffect(() => {
    // Set a timer to hide the thumbnail and play the video after 1 second
    const timer = setTimeout(() => {
      setShowThumbnail(false);
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, 1000); // 1 second delay

    // Clean up the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const location = useLocation();
  const [isHeaderVisible, setHeaderVisible] = useState(false);

// ---------------CSS------------------ //
const svgStyles = {
  transform: 'rotate(180deg)',
    width: '100%',
    marginBottom: '-1px'
};

const pathStyles = {
  transform: 'scalex(2.5)',
};

const cardbody ={
  // padding: '0 149px 0 194px !important'
  padding: '0 45px 0 50px',
}

const card1 = {
  height: '459px',
  padding: '19px',
};
const card2 = {
  height: '332px',
  padding: '19px',
};
const cardbody2 ={
  // padding: '0 149px 0 194px !important'
  padding: '0 68px 0 83px',
}

const stepsForShipment = {
  color: "#3E5E9A",
  // fontFamily: "Varela Round Sans-serif",
  fontSize: "45px",
  fontWeight: "400",
  textTransform: "capitalize",
  lineHeight: "1.2rem",
}
const ReadonsToChooseUs = {
              color: "#3E5E9A",
              fontSize: "45px",
              fontWeight: "400",
              textTransform: "capitalize",
              lineHeight: "1.2rem",
              marginBottom: "8rem",
}
const IndustriesWeServe = {
               color: "#3E5E9A",
              // fontFamily: "Varela Round Sans-serif",
              fontSize: "45px",
              fontWeight: "400",
              textTransform: "capitalize",
              lineHeight: "1.2rem",
              marginBottom: "2em",
            

}
const ourpreferredairlines={
  color: "#3E5E9A",
  fontSize: "45px",
  fontWeight: "400",
  textTransform: "capitalize",
  lineHeight: "1.2rem",
  marginBottom: "4rem",

}


// const Homestyles = `
// .mobileviewhomeM2 {
// color: rgb(255, 255, 255);
//         font-family: "Valera Round", sans-serif;
//         font-size: 40px;
//         font-weight: 700;
//         text-transform: capitalize;
//         mix-blend-mode: luminosity;
// },
// @media (max-width: 768px) {
// .mobile-view-home-M1:{
//     olor: rgb(255, 255, 255);
//     font-family: Montserrat, sans-serif;
//     font-size: 13px;
//     font-weight: 300;
//     text-transform: capitalize;
//     margin: 5px;
// }
// .mobileviewhomeM2:{
// color: rgb(255, 255, 255);
//     font-family: "Valera Round", sans-serif;
//     font-size: 15px;
//     font-weight: 700;
//     text-transform: capitalize;
//     mix-blend-mode: luminosity;
//     margin-bottom: 7px;
// }
// .mobile-view-home-M3:{
// color: rgb(255, 255, 255);
//     font-family: Montserrat, sans-serif;
//     font-size: 13px;
//     font-weight: 300;
//     text-transform: capitalize;
//     margin-bottom: 10px;
// }
//     .mobile-view-home-M4:{
//     background-color: rgb(243, 114, 102);
//     text-decoration: none;
//     border-radius: 30px;
//     width: 123px;
//     font-size: 12px;
//     }

// }`;

  return (
    <>
      <div className="Container ">
        {/* home main section  */}
        <div className="">
          <div className="relative space-y-6">
            {/* Thumbnail */}
            {showThumbnail && (
              <div className="absolute inset-0 z-10 bg-cover bg-center bottom-0 left-0 right-0 m-0 p-0"></div>
            )}

            {/* Video */}
            <video
              ref={videoRef}
              className="w-full object-cover Home-page-first-video"
              loop
              muted
            >
              <source src={flightland} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            {/* Video Overlay */}
            <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white z-20 p-4 md:p-6 lg:p-8 xl:p-10">
              <h3
                style={{
                  color: " #ffffff",
                  fontFamily: "Montserrat, Sans-serif",
                  fontSize: "20px",
                  fontWeight: "300",
                  textTransform: "capitalize",
                }}
                className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl mb-6 sm:mb-4 md:mb-6 lg:mb-8 mobile-view-home-M1"
              >
                Go Beyond Your Wildest Dreams
              </h3>
              <h1
                className="overlay-text  sm:text-xl md:text-3xl lg:text-4xl xl:text-5xl mb-6 sm:mb-4 md:mb-6 lg:mb-8 leading-tight md:leading-snug mobile-view-home-M2"
                
              >
                Delivery Beyond Boundaries
              </h1>
              <h3
                style={{
                  color: " #ffffff",
                  fontFamily: "Montserrat, Sans-serif",
                  fontSize: "20px",
                  fontWeight: "300",
                  textTransform: "capitalize",
                }}
                className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl mb-6 sm:mb-6 md:mb-8 lg:mb-10 mobile-view-home-M3"
              >
                Building innovative supply chain solutions for future
                generations.
              </h3>
              <a
                href="book.dbbworldwide.com"
                className="GetQuote-button inline-flex items-center py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg text-sm sm:text-base md:text-lg xl:text-xl transition duration-300 ease-in-out mobile-view-home-M4"
                style={{
                  color: "white",
                  backgroundColor: "#F37266",
                  textDecoration: "none",
                  borderRadius: "30px 30px 30px 30px",
                }}
              >
                <span className="learnMore_Text">Get Quote</span>
                <span className="arrow-quote ml-2">→</span>
              </a>
            </div>

            {/* Wave SVG */}
            <div className="absolute bottom-0 left-0 right-0" style={svgStyles}>
              <svg
                viewBox="0 0 1440 320"
                preserveAspectRatio="none"
                className="w-full"
              >
                <path style={pathStyles}
                  fill="#fff" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
                  ></path>
              </svg>
            </div>
          </div>
        </div>

        {/* video with content */}
        <div className="App_Content py-8 px-4 md:px-8 lg:px-16">
          <div className="container mx-auto flex flex-col lg:flex-row gap-8">
            <div className="Video_Container flex-1 lg:order-1">
              <div className="video-wrapper relative">
                {/* Video Thumbnail */}
                {!isPlaying && (
                  <div
                    className="thumbnail-wrapper relative flex items-center justify-center cursor-pointer"
                    onClick={handlePlayVideo}
                  >
                    <img
                      src={thumbnail}
                      alt="Video Thumbnail"
                      className="video-thumbnail w-full h-full object-cover"
                    />
                    <img
                      src={videobutton}
                      alt="Play Button"
                      className="video-button absolute w-30 h-20" // Adjust size here
                    />
                  </div>
                )}

                {/* Video Frame */}
                {isPlaying && (
                  <iframe
                    ref={videoRef}
                    className="w-full h-64 lg:w-full lg:h-80"
                    src="https://www.youtube.com/embed/F3MgBfMz0D4?autoplay=1"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube video"
                  ></iframe>
                )}
              </div>
            </div>

            <div className="Paragraph_Container flex-1 lg:order-2 mb-6">
              <h1
                className="text-2xl md:text-3xl font-bold mb-4"
                style={{
                  color: "#3E5E9A",
                  fontSize: "45px",
                  textTransform: "capitalize",
                  lineHeight: "3.2rem",
                  fontWeight: "300",
                  textAlign: 'start',
                  fontFamily: '"Varela Round", Sans-serif',
                }}
              >
                We Make Supply Chain Solutions That Help Your Business Grow
              </h1>
              <p
                className="text-base md:text-lg mb-4"
                style={{
                  color: "#444444",
                  fontFamily: "Roboto, Sans-serif",
                  textAlign: "justify",
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '15px',
                }}
              >
                DBB facilitates world-class freight services across the globe.
                We have been making the best possible efforts to improve our
                services to ensure and provide maximum satisfaction to our
                esteemed clients.
              </p>
              <div className="button_Container" style={{textAlign: 'justify'}}>
                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                    borderRadius: "30px 30px 30px 30px",
                    backgroundColor: "#F37266",
                  }}
                  href="#"
                  className="LearnMore_Button inline-block bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
                >
                  <span className="learnMore_Text">Learn More</span>
                  <span className="arrow-quote ml-2">→</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* carausel  */}
        <div className="Who-are-we bg-blue-600 py-8 px-4 md:px-8 lg:px-16 mb-8">
          {" "}
          {/* Added mb-8 for bottom margin */}
          <h1
            className="text-white text-3xl lg:text-4xl font-bold animate-slide-left-to-right mb-8  "
            style={{
              color: "#FFFFFF",
              fontFamily: "Varela Round, Sans-serif",
              fontSize: "45px",
              fontWeight: "300",
              textTransform: "capitalize",
            }}
          >
            We are DBB Worldwide
          </h1>
          <div className="relative">
            <Carousel
              autoPlay
              infiniteLoop
              interval={3000} // Time between slides
              showThumbs={false} // Hide thumbnails
              showIndicators={true} // Show navigation dots
              showStatus={false} // Hide status text
              swipeable={true} // Allow swiping
              emulateTouch={true} // Emulate touch on non-touch devices

              // You can add your breakPoints if needed
            >
              <div className="flex gap-8 p-8 bg-blue-600">
                <div className="flex-1 text-center text-white">
                  <h5
                    className="text-lg font-semibold mb-8"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Montserrat, Sans-serif",
                      fontSize: "18px",
                      fontWeight: "300",
                      fontStyle: "normal",
                    }}
                  >
                    We Deliver
                  </h5>
                  <p
                    className="text-2xl font-bold mb-8"
                    style={{
                      fontWeight: "700",
                      fontSize: "75px",
                      fontFamily: "Varela, Sans-serif",
                    }}
                  >
                    100+
                  </p>
                  <h1 className="text-xl">Postal Codes</h1>
                </div>

                <div className="flex-1 text-center text-white">
                  <h5
                    className="text-lg font-semibold mb-8"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Montserrat, Sans-serif",
                      fontSize: "18px",
                      fontWeight: "300",
                      fontStyle: "normal",
                    }}
                  >
                    We Cater
                  </h5>
                  <p
                    className="text-2xl font-bold mb-8"
                    style={{
                      fontWeight: "700",
                      fontSize: "75px",
                      fontFamily: "Varela, Sans-serif",
                    }}
                  >
                    100+
                  </p>
                  <h1 className="text-xl">Companies</h1>
                </div>
                <div className="flex-1 text-center text-white">
                  <h5
                    className="text-lg font-semibold mb-8"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Montserrat, Sans-serif",
                      fontSize: "18px",
                      fontWeight: "300",
                      fontStyle: "normal",
                    }}
                  >
                    We Partner
                  </h5>
                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: "75px",
                      fontFamily: "Varela, Sans-serif",
                    }}
                    className="text-2xl font-bold mb-8"
                  >
                    100+
                  </p>
                  <h1 className="text-xl">Airlines</h1>
                </div>
              </div>

              <div className="flex gap-8 p-8 bg-blue-600">
                <div className="flex-1 text-center text-white">
                  <h5
                    className="text-lg font-semibold"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Montserrat, Sans-serif",
                      fontSize: "18px",
                      fontWeight: "300",
                      fontStyle: "normal",
                    }}
                  >
                    We Freight
                  </h5>
                  <p
                    className="text-2xl font-bold mb-8"
                    style={{
                      fontWeight: "700",
                      fontSize: "75px",
                      fontFamily: "Varela, Sans-serif",
                    }}
                  >
                    100+
                  </p>
                  <h1 className="text-xl">Countries</h1>
                </div>
                <div className="flex-1 text-center text-white">
                  <h5
                    className="text-lg font-semibold mb-8"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Montserrat, Sans-serif",
                      fontSize: "18px",
                      fontWeight: "300",
                      fontStyle: "normal",
                    }}
                  >
                    We Deliver
                  </h5>
                  <p
                    className="text-2xl font-bold mb-8"
                    style={{
                      fontWeight: "700",
                      fontSize: "75px",
                      fontFamily: "Varela, Sans-serif",
                    }}
                  >
                    1500+
                  </p>
                  <h1 className="text-xl">Postal Codes</h1>
                </div>
                <div className="flex-1 text-center text-white">
                  <h5
                    className="text-lg font-semibold mb-8"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Montserrat, Sans-serif",
                      fontSize: "18px",
                      fontWeight: "300",
                      fontStyle: "normal",
                    }}
                  >
                    We Cater
                  </h5>
                  <p
                    className="text-2xl font-bold mb-8"
                    style={{
                      fontWeight: "700",
                      fontSize: "75px",
                      fontFamily: "Varela, Sans-serif",
                    }}
                  >
                    100+
                  </p>
                  <h1 className="text-xl mb-8">Companies</h1>
                </div>
              </div>

              <div className="flex gap-8 p-8 bg-blue-600">
                <div className="flex-1 text-center text-white">
                  <h2
                    className="text-xl font-semibold mb-8"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Montserrat, Sans-serif",
                      fontSize: "18px",
                      fontWeight: "300",
                      fontStyle: "normal",
                    }}
                  >
                    We Freight
                  </h2>
                  <p
                    className="text-2xl font-bold mb-8"
                    style={{
                      fontWeight: "700",
                      fontSize: "75px",
                      fontFamily: "Varela, Sans-serif",
                    }}
                  >
                    100+
                  </p>
                  <h1 className="text-xl mb-8">Countries</h1>
                </div>
                <div className="flex-1 text-center text-white">
                  <h2
                    className="text-xl font-semibold mb-8"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Montserrat, Sans-serif",
                      fontSize: "18px",
                      fontWeight: "300",
                      fontStyle: "normal",
                    }}
                  >
                    We Deliver
                  </h2>
                  <p
                    className="text-2xl font-bold mb-8"
                    style={{
                      fontWeight: "700",
                      fontSize: "75px",
                      fontFamily: "Varela, Sans-serif",
                    }}
                  >
                    1500+
                  </p>
                  <h1 className="text-xl">Postal Codes</h1>
                </div>
                <div className="flex-1 text-center text-white">
                  <h2
                    className="text-xl font-semibold mb-8"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Montserrat, Sans-serif",
                      fontSize: "18px",
                      fontWeight: "300",
                      fontStyle: "normal",
                    }}
                  >
                    We Cater
                  </h2>
                  <p
                    className="text-2xl font-bold mb-8"
                    style={{
                      fontWeight: "700",
                      fontSize: "75px",
                      fontFamily: "Varela, Sans-serif",
                    }}
                  >
                    100+
                  </p>
                  <h1 className="text-xl">Companies</h1>
                </div>
              </div>
            </Carousel>

            <Carousel
              autoPlay
              infiniteLoop
              interval={3000} // Time between slides
              showThumbs={false} // Hide thumbnails
              showIndicators={true} // Show navigation dots
              showStatus={false} // Hide status text
              swipeable={true} // Allow swiping
              emulateTouch={true} // Emulate touch on non-touch devices
            >
              <div className="flex gap-8 p-8 bg-blue-600">
                <div className="flex-1 text-center text-white">
                  <h3 className="text-lg font-semibold">Our Philosophy</h3>
                  <img
                    className="mx-auto object-contain w-full h-48 max-w-xs"
                    src={philosophy}
                    alt="Philosophy"
                  />
                  <p className="mt-4">
                    Timely Delivery without compromising on
                    <br />
                    safety. We believe in customer-first
                    <br />
                    approach and transparency in business
                  </p>
                </div>
                <div className="flex-1 text-center text-white">
                  <h3 className="text-lg font-semibold">Our Vision</h3>
                  <img
                    className="mx-auto object-contain w-full h-48 max-w-xs"
                    src={manwithscope}
                    alt="Vision"
                  />
                  <p className="mt-4">
                    Venturing into new supply chain technology
                    <br />
                    to innovate our products and services and
                    <br />
                    connect the world as one
                  </p>
                </div>
                <div className="flex-1 text-center text-white">
                  <h3 className="text-lg font-semibold">Our Mission</h3>
                  <img
                    className="mx-auto object-contain w-full h-48 max-w-xs"
                    src={Visson}
                    alt="Mission"
                  />
                  <p className="mt-4">
                    We strive to provide 360-degree supply chain
                    <br />
                    solutions for our clients. Some of our
                    <br />
                    attributes make us a trustworthy partner.
                  </p>
                </div>
              </div>
              <div className="flex gap-8 p-8 bg-blue-600">
                <div className="flex-1 text-center text-white">
                  <h3 className="text-lg font-semibold">Our Vision</h3>
                  <img
                    className="mx-auto object-contain w-full h-48 max-w-xs"
                    src={manwithscope}
                    alt="Vision"
                  />
                  <p className="mt-4">
                    Venturing into new supply chain technology
                    <br />
                    to innovate our products and services and
                    <br />
                    connect the world as one
                  </p>
                </div>
                <div className="flex-1 text-center text-white">
                  <h3 className="text-lg font-semibold">Our Mission</h3>
                  <img
                    className="mx-auto object-contain w-full h-48 max-w-xs"
                    src={Visson}
                    alt="Mission"
                  />
                  <p className="mt-4">
                    We strive to provide 360-degree supply chain
                    <br />
                    solutions for our clients. Some of our
                    <br />
                    attributes make us a trustworthy partner.
                  </p>
                </div>
                <div className="flex-1 text-center text-white">
                  <h3 className="text-lg font-semibold">Our Philosophy</h3>
                  <img
                    className="mx-auto object-contain w-full h-48 max-w-xs"
                    src={philosophy}
                    alt="Philosophy"
                  />
                  <p className="mt-4">
                    Timely Delivery without compromising on
                    <br />
                    safety. We believe in customer-first
                    <br />
                    approach and transparency in business
                  </p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>

        {/* our services  */}
        <div className="px-4 py-8">
          <div className="text-blue-700 font-varela-round text-4xl font-light capitalize leading-tight mt-16 mb-12 text-center">
            <span
              style={{
                color: "#3E5E9A",
                // fontFamily: "Varela Round Sans-serif",
                fontSize: "45px",
                fontWeight: "400",
                textTransform: "capitalize",
                lineHeight: "1.2rem",
              }}
            >
              Our Services
            </span>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-20 home-page-cardbody-M" style={cardbody}>
            <div
              className="flex flex-col items-center text-center"
              style={{
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.3)",
                border: "1px solid #efe6e6",
                borderRadius: "30px 30px 34px 100px",
                // width: "22rem",
                padding: "5px",
                ...card1,
              }}
            >
              <img
                src={AirFreight}
                className="h-20 object-contain"
                alt="Air Freight"
              />
              <h3
                className="text-xl font-semibold mt-4"
                style={{
                  fontFamily: "Varela Round, Sans-serif",
                  color: "#3d4459",
                  fontSize: "20px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                }}
              >
                Air Freight
              </h3>
              <p
                className="text-sm font-light mt-2 px-4"
                style={{
                  color: "#3d4459",
                  fontFamily: "Montserrat, Sans-serif",
                  fontSize: "15px",
                  fontWeight: "300",
                  lineHeight: "1.4rem",
                }}
              >
                Airfreight remains the best option if you have time constraints
                for your shipments. Besides providing timely deliveries and
                customer satisfaction, we ensure proper inspection and cargo
                handling as well.
              </p>
              <a
                href="www.dbbworldwide.com"
                className="text-blue-600 flex items-center justify-center mt-4"
                style={{ textDecoration: "none" }}
              >
                <span
                  className="flex items-center text-base"
                  style={{ fontWeight: "500" }}
                >
                  Learn More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="18"
                    fill="currentColor"
                    className="bi bi-arrow-right ml-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.146 2.854a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 1 1-.708-.708L14.293 9H1.5a.5.5 0 0 1 0-1h12.793l-4.147-4.146a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
              </a>
            </div>

            <div
              className="flex flex-col items-center text-center"
              style={{
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.3)",
                border: "1px solid #efe6e6",
                borderRadius: "30px 30px 34px 100px",
                // width: "22rem",
                ...card1,
              }}
            >
              <img
                src={SeaFreight}
                className="h-20 object-contain"
                alt="Sea Freight"
              />
              <h3
                className="text-xl font-semibold mt-4"
                style={{
                  fontFamily: "Varela Round, Sans-serif",
                  color: "#3d4459",
                  fontSize: "20px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                }}
              >
                Sea Freight
              </h3>
              <p
                className="text-sm font-light mt-2 px-4"
                style={{
                  color: "#3d4459",
                  fontFamily: "Montserrat, Sans-serif",
                  fontSize: "15px",
                  fontWeight: "300",
                  lineHeight: "1.4rem",
                }}
              >
                Sea freight is the most pocket-friendlier way of shipments. If
                there is no time constraint, then you should rather go for it.
                It is more convenient besides incurring you a lower price
                compared to Air and Road freights.
              </p>
              <a
                href="www.dbbworldwide.com"
                className="text-blue-600 flex items-center justify-center mt-4"
                style={{ textDecoration: "none" }}
              >
                <span
                  className="flex items-center text-base"
                  style={{ fontWeight: "500" }}
                >
                  Learn More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="18"
                    fill="currentColor"
                    className="bi bi-arrow-right ml-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.146 2.854a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 1 1-.708-.708L14.293 9H1.5a.5.5 0 0 1 0-1h12.793l-4.147-4.146a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
              </a>
            </div>

            <div
              className="flex flex-col items-center text-center"
              style={{
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.3)",
                border: "1px solid #efe6e6",
                borderRadius: "30px 30px 34px 100px",
                // width: "22rem",
                ...card1,
              }}
            >
              <img
                src={GroundShipping}
                className="h-20 object-contain"
                alt="Ground Shipping"
              />
              <h3
                className="text-xl font-semibold mt-4"
                style={{
                  fontFamily: "Varela Round, Sans-serif",
                  color: "#3d4459",
                  fontSize: "20px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                }}
              >
                Ground Shipping
              </h3>
              <p
                className="text-sm font-light mt-2 px-4"
                style={{
                  color: "#3d4459",
                  fontFamily: "Montserrat, Sans-serif",
                  fontSize: "15px",
                  fontWeight: "300",
                  lineHeight: "1.4rem",
                }}
              >
                Ground shipping is an important part of the shipping works
                whether through Air, Sea, or Railways. It collects your shipment
                from the place of pickup and then delivers it to the required
                destinations.
              </p>
              <a
                href="www.dbbworldwide.com"
                className="text-blue-600 flex items-center justify-center mt-4"
                style={{ textDecoration: "none" }}
              >
                <span
                  className="flex items-center text-base"
                  style={{ fontWeight: "500" }}
                >
                  Learn More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="18"
                    fill="currentColor"
                    className="bi bi-arrow-right ml-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.146 2.854a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 1 1-.708-.708L14.293 9H1.5a.5.5 0 0 1 0-1h12.793l-4.147-4.146a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
              </a>
            </div>

            <div
              className="flex flex-col items-center text-center"
              style={{
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.3)",
                border: "1px solid #efe6e6",
                borderRadius: "30px 30px 34px 100px",
                // width: "22rem",
                ...card1
              }}
            >
              <img
                src={warehousing}
                className="h-20 object-contain"
                alt="Warehousing"
              />
              <h3
                className="text-xl font-semibold mt-4"
                style={{
                  fontFamily: "Varela Round, Sans-serif",
                  color: "#3d4459",
                  fontSize: "20px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                }}
              >
                Warehousing
              </h3>
              <p
                className="text-sm font-light mt-2 px-4"
                style={{
                  color: "#3d4459",
                  fontFamily: "Montserrat, Sans-serif",
                  fontSize: "15px",
                  fontWeight: "300",
                  lineHeight: "1.4rem",
                }}
              >
                If you seek long-term storage or multiple storages across the
                country, we can help you out with that. You don't need to have
                multiple numbers of different warehouses while you can just have
                one contact for all.
              </p>
              <a
                href="www.dbbworldwide.com"
                className="text-blue-600 flex items-center justify-center mt-4"
                style={{ textDecoration: "none" }}
              >
                <span
                  className="flex items-center text-base"
                  style={{ fontWeight: "500" }}
                >
                  Learn More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="18"
                    fill="currentColor"
                    className="bi bi-arrow-right ml-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.146 2.854a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 1 1-.708-.708L14.293 9H1.5a.5.5 0 0 1 0-1h12.793l-4.147-4.146a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
              </a>
            </div>

            <div
              className="flex flex-col items-center text-center"
              style={{
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.3)",
                border: "1px solid #efe6e6",
                borderRadius: "30px 30px 34px 100px",
                // width: "22rem",
                ...card1
              }}
            >
              <img
                src={custom}
                className="h-20 object-contain"
                alt="Clearance"
              />
              <h3
                className="text-xl font-semibold mt-4"
                style={{
                  fontFamily: "Varela Round, Sans-serif",
                  color: "#3d4459",
                  fontSize: "20px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                }}
              >
                Clearance
              </h3>
              <p
                className="text-sm font-light mt-2 px-4"
                style={{
                  color: "#3d4459",
                  fontFamily: "Montserrat, Sans-serif",
                  fontSize: "15px",
                  fontWeight: "300",
                  lineHeight: "1.4rem",
                }}
              >
                Custom clearance is one of the major tasks in freight services
                and we understand our customers' pain of custom clearance. Hence
                we provide end-to-end custom support and solutions to our
                customers.
              </p>
              <a
                href="www.dbbworldwide.com"
                className="text-blue-600 flex items-center justify-center mt-4"
                style={{ textDecoration: "none" }}
              >
                <span
                  className="flex items-center text-base"
                  style={{ fontWeight: "500" }}
                >
                  Learn More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="18"
                    fill="currentColor"
                    className="bi bi-arrow-right ml-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.146 2.854a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 1 1-.708-.708L14.293 9H1.5a.5.5 0 0 1 0-1h12.793l-4.147-4.146a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
              </a>
            </div>

            <div
              className="flex flex-col items-center text-center"
              style={{
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.3)",
                border: "1px solid #efe6e6",
                borderRadius: "30px 30px 34px 100px",
                // width: "22rem",
                ...card1
              }}
            >
              <img
                src={documen}
                className="h-20 object-contain"
                alt="Documentation"
              />
              <h3 className="text-xl font-semibold mt-4" 
               style={{
                fontFamily: "Varela Round, Sans-serif",
                color: "#3d4459",
                fontSize: "20px",
                fontWeight: "400",
                textTransform: "capitalize",
              }}>Documentation</h3>
              <p
                className="text-sm font-light mt-2 px-4"
                style={{
                  color: "#3d4459",
                  fontFamily: "Montserrat, Sans-serif",
                  fontSize: "15px",
                  fontWeight: "300",
                  
                  lineHeight: "1.4rem",
                }}
              >
                When you want to expand your business and seek trades with the
                global markets, there is an ardent need to abide by the laws and
                regulations of the respective regions in the global markets you
                deal in.
              </p>
              <a
                href="www.dbbworldwide.com"
                className="text-blue-600 flex items-center justify-center mt-4"
                style={{ textDecoration: "none" }}
              >
                <span
                  className="flex items-center text-base"
                  style={{ fontWeight: "500" }}
                >
                  Learn More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="18"
                    fill="currentColor"
                    className="bi bi-arrow-right ml-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.146 2.854a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 1 1-.708-.708L14.293 9H1.5a.5.5 0 0 1 0-1h12.793l-4.147-4.146a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>

        {/* steps for shipment  */}
        <div className="process px-4 py-2">
          <div className="text-blue-700 font-varela-round text-2xl font-light capitalize leading-tight text-center mb-8">
            <h2 className="steps-For-Shipment-M"
              style={stepsForShipment}
            >
              Steps for Shipment
            </h2>
          </div>

          <div className="flex justify-center">
            <img src={process} alt="Process" className="w-full lg:w-[600px]" />
          </div>
        </div>

        {/*-----------------------------------------Reasons-To-Choose-Us-Start-------------------------------*/}

        <div className="px-4 py-8">
          <h2
            className="Reason-to-choose block text-blue-700 font-varela-round text-2xl font-light capitalize leading-tight text-center mb-8 Reason-to-choose-M"
            style={ ReadonsToChooseUs}
          >
            Reasons to Choose Us
          </h2>

          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 home-page-card-body2-M" style={cardbody2}>
            <div
              className="flex flex-col items-center text-center"
              style={{
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.3)",
                border: "1px solid #efe6e6",
                borderRadius: "30px 30px 34px 100px",
                // width: "22rem",
                height: "18rem",
                padding: "5px",
                ...card2,
              }}
            >
              <img
                src={customer}
                alt="Customer Satisfaction"
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold mb-2">
                Greater customer satisfaction
              </h3>
              <p className="text-gray-700">
                Our clients made us who we are today so do we also try to
                provide them with the maximum satisfaction.
              </p>
            </div>

            <div
              className="flex flex-col items-center text-center"
              style={{
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.3)",
                border: "1px solid #efe6e6",
                borderRadius: "30px 30px 34px 100px",
                // width: "22rem",
                height: "18rem",
                padding: "5px",
                ...card2,
              }}
            >
              <img
                src={SeaFreight}
                alt="Easy International Shipping"
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold mb-2">
                Easy international shipping
              </h3>
              <p className="text-gray-700">
                Wide range of services and door-to-door shipping options
                available for your shipping needs across borders.
              </p>
            </div>

            <div
              className="flex flex-col items-center text-center"
              style={{
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.3)",
                border: "1px solid #efe6e6",
                borderRadius: "30px 30px 34px 100px",
                // width: "22rem",
                height: "18rem",
                padding: "5px",
                ...card2,
              }}
            >
              <img
                src={GroundShipping}
                alt="Guidance"
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold mb-2">Guidance</h3>
              <p className="text-gray-700">
                We provide you with the best estimate of time and the expenses
                involved in freight along with thorough guidance.
              </p>
            </div>

            <div
              className="flex flex-col items-center text-center"
              style={{
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.3)",
                border: "1px solid #efe6e6",
                borderRadius: "30px 30px 34px 100px",
                // width: "22rem",
                height: "18rem",
                padding: "5px",
                ...card2,
              }}
            >
              <img
                src={competitive}
                alt="Competitive"
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold mb-2">Competitive</h3>
              <p className="text-gray-700">
                We provide discounted prices for the shipments and never charge
                too high from our clients for the services offered.
              </p>
            </div>

            <div
              className="flex flex-col items-center text-center"
              style={{
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.3)",
                border: "1px solid #efe6e6",
                borderRadius: "30px 30px 34px 100px",
                // width: "22rem",
                height: "18rem",
                padding: "5px",
                ...card2
              }}
            >
              <img
                src={trusted}
                alt="Trusted services"
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold mb-2">Trusted services</h3>
              <p className="text-gray-700">
                We have a team of experts that will take care of your order from
                the time it is picked until delivered.
              </p>
            </div>

            <div
              className="flex flex-col items-center text-center"
              style={{
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.3)",
                border: "1px solid #efe6e6",
                borderRadius: "30px 30px 34px 100px",
                // width: "22rem",
                height: "18rem",
                padding: "5px",
                ...card2,
              }}
            >
              <img src={shop} alt="One stop shop" className="w-16 h-16 mb-4" />
              <h3 className="text-xl font-semibold mb-2">One stop shop</h3>
              <p className="text-gray-700">
                We are the one-stop solution for all your shipment needs whether
                they be through Air or Sea or Railways.
              </p>
            </div>
          </div>
        </div>

        {/*-----------------------------------------Reasons-To-Choose-Us-End-------------------------------*/}

        {/*-----------------------------------------Industries-We-Serve-Start-------------------------------*/}

        <div className="px-4 py-8">
          <span
            className="Reason-to-choose block text-blue-700 font-varela-round text-2xl font-light capitalize leading-tight text-center mb-8 industries-We-Serve-M"
            style={IndustriesWeServe
              }
          >
            Industries We Serve
          </span>
        </div>

        <Carousel className="carouselpadding_change carouselpadding_change-M "
          autoPlay
          infiniteLoop
          interval={3000} // Time between slides
          showThumbs={false} // Hide thumbnails
          showIndicators={true} // Show navigation dots
          showStatus={false} // Hide status text
          swipeable={true} // Allow swiping
          emulateTouch={true} // Emu
        >
          <div className="flex gap-4 p-4">
            <div className="flex-1 slider-every-img">
              <img
                src={heart}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
              <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {" "}
                Textile
              </span>
            </div>
            <div className="flex-1 slider-every-img">
              <img
                src={jewel}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
              <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Jewellery and Handicraft
              </span>
            </div>
            <div className="flex-1 slider-every-img">
              <img
                src={Doc}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
             <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Pharmaceutical
              </span>
            </div>
            <div className="flex-1 slider-every-img">
              <img
                src={man}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
             <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Documentation
              </span>
            </div>
            <div className="flex-1 slider-every-img">
              <img
                src={Astro}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
             <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Dangerous Goods
              </span>
            </div>
          </div>

          <div className="flex gap-4 p-4">
            <div className="flex-1 slider-every-img">
              <img
                src={factory}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
             <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Dangerous Goods
              </span>
            </div>

            <div className="flex-1 slider-every-img">
              <img
                src={jewel}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
             <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Textile
              </span>
            </div>
            <div className="flex-1 slider-every-img">
              <img
                src={ball}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
             <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Sports
              </span>
            </div>
            <div className="flex-1 slider-every-img">
              <img
                src={Straw}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
             <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Food
              </span>
            </div>
            <div className="flex-1 slider-every-img">
              <img
                src={Astro}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
             <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Dangerous Goods
              </span>
            </div>
          </div>

          <div className="flex gap-4 p-4">
            <div className="flex-1 slider-every-img">
              <img
                src={factory}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
             <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Dangerous Goods
              </span>
            </div>

            <div className="flex-1 slider-every-img">
              <img
                src={Straw}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
             <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Food
              </span>
            </div>
            <div className="flex-1 slider-every-img">
              <img
                src={Astro}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
             <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Dangerous Goods
              </span>
            </div>

            <div className="flex-1 slider-every-img">
              <img
                src={jewel}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
             <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Textile
              </span>
            </div>
            <div className="flex-1 slider-every-img">
              <img
                src={ball}
                alt="mukul"
                style={{
                  borderRadius: "90px 20px 20px 90px",
                }}
              />
              <span
                style={{
                  color: "#3E5E9A",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Sports
              </span>
            </div>
          </div>
        </Carousel>

        {/*-----------------------------------------Industries-We-Serve-End-------------------------------*/}

        {/*-----------------------------------------Founder-We-Serve-Start-------------------------------*/}

        <section className="flex flex-col md:flex-row md:space-x-6 items-center mt-10  p-20 founder-we-server-start-M">
          <div className="w-full max-w-sm bg-white border border-gray-200 rounded-3xl shadow-lg overflow-hidden flex flex-col faisal-Islam-M">
            <img
              src={faisal}
              alt="Faisal Islam"
              className="w-full h-[280px] object-cover transition duration-300 ease-in-out transform hover:brightness-75"
            />
            <div className="p-4 flex flex-col flex-grow text-center">
              <h3
                style={{ fontFamily: "Montserrat, Sans-serif", color: '#3E5E9A', fontWeight: '400', lineHeight: '0.1' }}
                className="text-xl font-semibold text-blue-800"
              >
                Faisal Islam
              </h3>
              <h4
                style={{ fontFamily: "Montserrat, Sans-serif" }}
                className="text-xl font-medium text-gray-600"
              >
                Founder
              </h4>
              <p
                style={{ fontFamily: "Montserrat, Sans-serif" }}
                className="text-gray-700 text-base mt-2"
              >
                Faisal Islam MD. Aims to build a bond between the clients on
                trust. He further plans to make DBB a leading freight forwarding
                company in the market. It is with the ample guidance and efforts
                of both the key members that DBB has been constantly soaring
                high.
              </p>
            </div>
          </div>

          <div className="w-full max-w-sm bg-white border border-gray-200 rounded-3xl shadow-lg overflow-hidden flex flex-col ">
            <img
              src={Amir}
              alt="Aamir Islam"
              className="w-full h-[280px] object-cover transition duration-300 ease-in-out transform hover:brightness-75"
            />

            <div className="p-4 flex flex-col flex-grow text-center">
              <h3
                style={{ fontFamily: "Montserrat, Sans-serif",color: '#3E5E9A', fontWeight: '400', lineHeight: '0.1' }}
                className="text-xl font-semibold text-blue-800"
              >
                Aamir Islam
              </h3>
              <h4
                style={{ fontFamily: "Montserrat, Sans-serif" }}
                className="text-xl font-medium text-gray-600"
              >
                Founder
              </h4>
              <p
                style={{ fontFamily: "Montserrat, Sans-serif" }}
                className="text-gray-700 text-base mt-2"
              >
                The vision and thought behind creating DBB worldwide as a
                customer-centric product that shapes who we are today going to
                none other than our Founder, Mr Aamir Islam. His untiring
                efforts, dedication and visionary approach made DBB reach new
                heights each day.
              </p>
            </div>
          </div>

          <div className="w-full mt-8 md:mt-0 text-center">
            <span
              style={{ fontFamily: "Montserrat, Sans-serif" }}
              className="text-lg  text-blue-800"
            >
              Our work inspires smiles
            </span>
            <h2
              style={{ fontFamily: "Montserrat, Sans-serif" }}
              className="text-2xl md:text-3xl lg:text-4xl  text-blue-800 mt-2"
            >
              The Faces Behind Our Success
            </h2>
          </div>
        </section>

  
        <div className="px-4 py-8">
          <span
            className="Reason-to-choose block text-blue-700 font-varela-round text-2xl font-light capitalize leading-tight text-center mb-8"
            style={{
              color: "#3E5E9A",
              // fontFamily: "Varela Round Sans-serif",
              fontSize: "45px",
              fontWeight: "400",
              textTransform: "capitalize",
              lineHeight: "1.2rem",
              marginBottom: "8rem",
            }}
          >
            Track Your Order
          </span>

          <div className=" flex flex-col md:flex-row lg:flex-row justify-center gap-4 mt-4">
            <div
              style={{ borderRadius: "20px 20px 20px 90px" }}
              className="flex flex-col items-center bg-white p-6 rounded-lg shadow-md w-full sm:w-1/2 md:w-1/3 lg:w-1/5 mr:2text-center"
            >
              <img
                src={airoplane}
                alt="Air Mode Shipment"
                className="w-16 h-16 mb-4"
              />
              <h3
                style={{
                  fontFamily: '"Montserrat", Sans-serif',
                  color: "#3d4459",
                  fontWeight: "400",
                  fontSize: "19px",
                  textTransform: "capitalize",
                }}
                className="text-xl font-semibold mb-2"
              >
                Air Mode Shipment
              </h3>
              <a
                href="https://www.dbbworldwide.com"
                className="flex items-center justify-center text-blue-500 hover:underline"
                style={{ textDecoration: "none" }}
              >
                <span className="mr-2">Track Order</span>
                <svg
                  className="arrow-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.146 2.854a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 1 1-.708-.708L14.293 9H1.5a.5.5 0 0 1 0-1h12.793l-4.147-4.146a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
            </div>

            <div
              style={{ borderRadius: "20px 20px 20px 90px" }}
              className="track-order-inner-container flex flex-col items-center bg-white p-6 rounded-lg shadow-md w-full sm:w-1/2 md:w-1/3 lg:w-1/5 text-center"
            >
              <img
                src={sea}
                alt="Sea Mode Shipment"
                className="w-16 h-16 mb-4"
              />
              <h3
                style={{
                  fontFamily: '"Montserrat", Sans-serif',
                  color: "#3d4459",
                  fontWeight: "400",
                  fontSize: "19px",
                  textTransform: "capitalize",
                }}
                className="text-xl font-semibold mb-2"
              >
                Sea Mode Shipment
              </h3>{" "}
              <a
                href="https://www.dbbworldwide.com"
                className="flex items-center justify-center text-blue-500 hover:underline"
                style={{ textDecoration: "none" }}
              >
                <span className="mr-2">Track Order</span>
                <svg
                  className="arrow-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.146 2.854a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 1 1-.708-.708L14.293 9H1.5a.5.5 0 0 1 0-1h12.793l-4.147-4.146a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
            </div>

            <div
              style={{ borderRadius: "20px 20px 20px 90px" }}
              className="track-order-inner-container flex flex-col items-center bg-white p-6 rounded-lg shadow-md w-full sm:w-1/2 md:w-1/3 lg:w-1/5 text-center"
            >
              <img
                src={container}
                alt="Domestic Container Tracking"
                className="w-16 h-16 mb-4"
              />
              <h3
                style={{
                  fontFamily: '"Montserrat", Sans-serif',
                  color: "#3d4459",
                  fontWeight: "400",
                  fontSize: "19px",
                  textTransform: "capitalize",
                }}
                className="text-xl font-semibold mb-2"
              >
                Domestic Container Tracking
              </h3>{" "}
              <a
                href="https://www.dbbworldwide.com"
                className="flex items-center justify-center text-blue-500 hover:underline"
                style={{ textDecoration: "none" }}
              >
                <span className="mr-2">Track Order</span>
                <svg
                  className="arrow-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.146 2.854a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 1 1-.708-.708L14.293 9H1.5a.5.5 0 0 1 0-1h12.793l-4.147-4.146a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
            </div>

            <div
              style={{ borderRadius: "20px 20px 20px 90px" }}
              className="track-order-inner-container flex flex-col items-center bg-white p-6 rounded-lg shadow-md w-full sm:w-1/2 md:w-1/3 lg:w-1/5 text-center"
            >
              <img
                src={anchor}
                alt="Shipping Bill Status"
                className="w-16 h-16 mb-4"
              />
              <h3
                style={{
                  fontFamily: '"Montserrat", Sans-serif',
                  color: "#3d4459",
                  fontWeight: "400",
                  fontSize: "19px",
                  textTransform: "capitalize",
                }}
                className="text-xl font-semibold mb-2"
              >
                Ship Bill Status
              </h3>{" "}
              <a
                href="https://www.dbbworldwide.com"
                className="flex items-center justify-center text-blue-500 hover:underline"
                style={{ textDecoration: "none" }}
              >
                <span className="mr-2">Track Order</span>
                <svg
                  className="arrow-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.146 2.854a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 1 1-.708-.708L14.293 9H1.5a.5.5 0 0 1 0-1h12.793l-4.147-4.146a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>

        {/*-------------------------------Our Preferred Airlines-------------------*/}

     
        <div className="px-4 py-8">
          <span
            className="Reason-to-choose block text-blue-700 font-varela-round text-2xl font-light capitalize leading-tight text-center mb-8 our-Preferre-Dairlines-M"
            style={ourpreferredairlines
             }
          >
            our preferred airlines
          </span>
          <Carousel
            autoPlay
            infiniteLoop
            interval={3000} // Time between slides
            showThumbs={false} // Hide thumbnails
            showIndicators={true} // Show navigation dots
            showStatus={false} // Hide status text
            swipeable={true} // Allow swiping
            emulateTouch={true} // Emu
          >
            <div className="flex gap-12 p-12">
              <div className="flex-1">
                <img
                  src={virgin}
                  style={
                    {
               
                    }
                  }
                />
              </div>
              <div className="flex-1">
                <img
                  src={singapore}
                  style={
                    {
                  
                    }
                  }
                />
           
              </div>
              <div className="flex-1">
                <img
                  src={AirIndia}
                  style={
                    {
                  
                    }
                  }
                />
              
              </div>
              <div className="flex-1">
                <img
                  src={lufthansa}
                  style={
                    {
                  
                    }
                  }
                />
             
              </div>
              <div className="flex-1">
                <img
                  src={virgin}
                  style={
                    {
              
                    }
                  }
                />
              </div>
              <div className="flex-1">
                <img
                  src={swis}
                  style={
                    {
                  
                    }
                  }
                />
             
              </div>
              <div className="flex-1">
                <img
                  src={qatar}
                  style={
                    {
                    
                    }
                  }
                />
          
              </div>
            </div>

            <div className="flex gap-12 p-12">
              <div className="flex-1">
                <img
                  src={lufthansa}
                  style={
                    {
                   
                    }
                  }
                />
            
              </div>
              <div className="flex-1">
                <img
                  src={virgin}
                  style={
                    {
                   
                    }
                  }
                />
              </div>
              <div className="flex-1">
                <img
                  src={swis}
                  style={
                    {
                 
                    }
                  }
                />
           
              </div>
              <div className="flex-1">
                <img
                  src={qatar}
                  style={
                    {
                    
                    }
                  }
                />
           
              </div>

              <div className="flex-1">
                <img
                  src={virgin}
                  style={
                    {
                 
                    }
                  }
                />
              </div>
              <div className="flex-1">
                <img
                  src={singapore}
                  style={
                    {
                  
                    }
                  }
                />
           
              </div>
              <div className="flex-1">
                <img
                  src={AirIndia}
                  style={
                    {
                      
                    }
                  }
                />
           
              </div>
            </div>
          </Carousel>
        </div>

        <div className="logistics_section">
          <div
            className="elementor-shape elementor-shape-top"
            data-negative="false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 100"
              preserveAspectRatio="none"
            >
              <path
                className="elementor-shape-fill"
                d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
              ></path>
            </svg>
          </div>
          <div className="content our-preferred-airlines-M">
            <div className="text-content">
              <h4>
                Gain An Edge Over The Competitors Through Superior Logistics?
              </h4>
              <p>Drop Us A Line And Experience The Difference</p>
              <button className="btn btn-primary" href='#'>Get Quote</button>
            </div>
            <img src={logic} className="logistics_image" alt="logistics" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
