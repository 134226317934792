import React, { useState } from "react";
import "./FAQs.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import men from "../../Assets/man.jpg";

const steps = [
  {
    title: "RESERVATION OF SHIPPING SPACE AND HANDOVER OF GOODS TO THE COMPANY",
    content:
      "Once the shipment is finalized the exporter reserves the required space in the vessel or aircraft for shipment. On his request DBB will issue an AWB or B/L. The draft copy of AWB or B/L is given to the exporter for approval. AWB or B/L is like an instruction to the airlines or vessel operator that the goods as per details given should be received on board.",
  },
  {
    title: "HANDOVER GOOD TO AIRLINE/SEA LINE",
    content: "The cargo is then on its way to the destination.",
  },
  {
    title: "ARRANGEMENT OF INTERNAL TRANSPORTATION UP TO THE PORT OF SHIPMENT",
    content:
      "Here DBB makes arrangements to ship the goods to the port through the most convenient vehicle having all the specific tools required for the items",
  },

  {
    title: "SHIPMENT DELIVERED TO DESTINATION",
    content: "",
  },

  {
    title: "PREPRATION AND PROCESSING OF SHIPPING DOCUMENTS",
    content:
      "The exported is required to issue detailed documents and submit them to the DBB. This includes AWB/BL instructions, custom documents to file shipping bill in customs and executing TC.",
  },
  {
    title: "SENDING POST SHIPMENT DOCUMENTS",
    content:
      "The post shipment documents are sent to the exporter by the shipping company bearing all the details of the shipment.",
  },

  {
    title: "CUSTOM CLEARANCE",
    content:
      "The cargo must be custom cleared before being boarded on the ship or plane. The custom appraiser ensures that all the formalities related to exchange control, quality control, pre- shipment inspection and licensing have been complied with the exporter. After verification, custom officials issue LEO (LET EXPORT ORDER).",
  },

  {
    title: "SATISFACTION AT THE END",
    content: "",
  },
];

const step2 = [
  {
    title: "Q1. what items can i ship?",
    content:
      "DBB provides an individual logistics solution for almost everything. Depending on your goods, our team suggests the best freight solution.",
  },
  {
    title:
      "Q8.what are dangerous goods, what item are considered as hazardouse?",
    content:
      "Dangerous goods are all loads, which under certain circumstances of transportation or storage may harm the environment, people and/or property; may cause explosion or fire, damage vehicles, buildings and/or constructions, as well as cause death, injury, poisoning, burns or diseases of people, animals and/or birds.",
  },
  {
    title: "Q2.what is the minimum weight for air cargo?",
    content:
      "Ans: The minimum weight we recommend is 25 kg, less than that we recommend to be delivered by courier mode.",
  },
  {
    title: "Q9.is it necessary to insure my cargo?",
    content:
      "Ans: Cargo insurance is not mandatory but we highly recommend it as it covers all the damage of the goods in any instance.",
  },
  {
    title: "Q3.what is air cargo limit?",
    content: "Ans: There is no limit. We can ship from pin to a plane.",
  },
  {
    title: "Q10.how can i make payment?",
    content: "Ans: We accept cheque and bank transfer.",
  },
  {
    title: "Q4.how quickly can my shipment be delivered?",
    content:
      "Ans: The delivery of your shipment depend on your requirements. DBB can customize a solution that can meet your specific needs..",
  },
  {
    title: "Q11.what documentation is required for export of goods?",
    content:
      "Ans: Basically it requires a KYC( Know Your Customer ) Invoice, Packing list, SDF & Drawback Annexures , AWB or B/L instructions and other specific documentation as specified by customs, or as required by the other government agencies based on the commodity we are exporting.",
  },
  {
    title: "Q5.how do i check the status of my shipment?",
    content:
      "Ans: Orders may be tracked from the DBB website under ‘Track my order ‘box on the side panel.",
  },
  {
    title: "Q12.what documentation is required for import of goods?",
    content:
      "Ans: Basically it requires a KYC( Know Your Customer ) AWB or B/L Invoice, Packing list and other specific documentation as specified by destination customs, or as required by the other government agencies based on the commodity we are importing..",
  },
  {
    title: "Q6.what is custom clearance?",
    content:
      "Ans: Custom clearance involves submission of required documents to facilitate import and export of goods into a country, representing the client during custom examination, assessment, payment of duty, delivery of Cargo after clearance along with the documents.",
  },

  {
    title: "Q13.what is airway bill and b/l?",
    content:
      "Ans: Airway bill and BL is an assigned number specific to the shipment by Airlines or Sea line which is used to make bookings, check status of delivery and current position of shipment.",
  },

  {
    title:
      "Q7.whome should i contact if i have any query related to my shipment?",
    content:
      "Ans: You may contact a representative in DBB‘s customer support department or may send an email at info[at]dbbworldwide[dot]com",
  },
];

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const pathStyles = {
    transform: 'scalex(2.5)',
  };
  const svgStyles = {
    transform: 'rotate(180deg)',
      width: '100%',
  };

  return (
//     <div className="FAQ-container">
  
//      <section 
//    className="services_header_section h-[500px] bg-custom-background flex items-center justify-center relative">
 
//   <div className="wave">
//   <svg viewBox="0 0 1440 320" preserveAspectRatio="none">
//           <path fill="#ffffff" d="M0,288L48,272C96,256,192,224,288,192C384,160,480,128,576,133.3C672,139,768,181,864,197.3C960,213,1056,203,1152,186.7C1248,171,1344,149,1392,138.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
//   </svg>
// </div>
//   <div className="relative z-10 text-center">
//     <h1 style={{ fontFamily: 'Varela Round , Sans-serif' }} className="text-[#fff] font-[Sans-serif] text-5xl font-bold">knowladge hub</h1>
//     <p style={{ fontFamily: 'Varela Round , Sans-serif' }} className="py-2 text-[#fff] text-xl">behind the scenes at beyond</p>
//   </div>
// </section>
//       <div className="accordion">
//         <h2>How It Works</h2>
//         <p>The Shipping Process Involves The Following Steps:</p>
        
//         <div className="accordion-grid">
//           {steps.map((step, index) => (
//             <div key={index} className="accordion-item">
//               <div
//                 className="accordion-title"
//                 onClick={() => handleToggle(index)}
//               >
//                 {step.title}
//                 <span
//                   className={`arrow ${activeIndex === index ? "up" : "down"}`}
//                 ></span>
//               </div>
//               <div
//                 className={`accordion-content ${
//                   activeIndex === index ? "show" : ""
//                 }`}
//               >
//                 {step.content}
//               </div>
//             </div>
//           ))}
//         </div>

//         <div style={{ marginTop: "15rem" }}>
//           <p>We Have Great Answers:</p>

//           <h2>FAQs</h2>
//           <div className="accordion-grid">
//             {step2.map((step, index) => (
//               <div key={index} className="accordion-item">
//                 <div
//                   className="accordion-title"
//                   onClick={() => handleToggle(index)}
//                 >
//                   {step.title}
//                   <span
//                     className={`arrow ${activeIndex === index ? "up" : "down"}`}
//                   ></span>
//                 </div>
//                 <div
//                   className={`accordion-content ${
//                     activeIndex === index ? "show" : ""
//                   }`}
//                 >
//                   {step.content}
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//       {/* <Footer /> */}
//     </div>
<div className="FAQ-container">
<sefction className="services_header_section h-[500px] bg-custom-background flex items-center justify-center relative">
<div className="absolute bottom-0 left-0 right-0" style={svgStyles}>
              <svg style={{marginTop: '-1px'}}
                viewBox="0 0 1440 320"
                preserveAspectRatio="none"
                className="w-full"
              >
                <path style={pathStyles}
                  fill="#fff" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
                  ></path>
              </svg>
            </div>
  <div className="relative z-10 text-center">
    <h1 style={{ fontFamily: 'Varela Round , Sans-serif' }} className="text-[#fff] font-[Sans-serif] text-5xl font-bold">Knowledge Hub</h1>
    <p style={{ fontFamily: 'Varela Round , Sans-serif' }} className="py-2 text-[#fff] text-xl">Behind the scenes at Beyond</p>
  </div>
</sefction>

<div className="accordion">
  <h2>How It Works</h2>
  <p>The Shipping Process Involves The Following Steps:</p>
  
  <div className="accordion-grid">
    {steps.map((step, index) => (
      <div key={index} className="accordion-item">
        <div className="accordion-title" onClick={() => handleToggle(index)}>
          {step.title}
          <span className={`arrow ${activeIndex === index ? "up" : "down"}`}></span>
        </div>
        <div className={`accordion-content ${activeIndex === index ? "show" : ""}`}>
          {step.content}
        </div>
      </div>
    ))}
  </div>

  <div style={{ marginTop: "15rem" }}>
    <p>We Have Great Answers:</p>
    <h2>FAQs</h2>
    <div className="accordion-grid">
      {step2.map((step, index) => (
        <div key={index} className="accordion-item">
          <div className="accordion-title" onClick={() => handleToggle(index)}>
            {step.title}
            <span className={`arrow ${activeIndex === index ? "up" : "down"}`}></span>
          </div>
          <div className={`accordion-content ${activeIndex === index ? "show" : ""}`}>
            {step.content}
          </div>
        </div>
      ))}
    </div>
  </div>
</div>
{/* <Footer /> */}
</div>
  );
};

export default FAQs;
