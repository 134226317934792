// import logo from './logo.svg';
// import './App.css';
// import Home from "./Components/Home/Home";
// import Footer from "./Components/Footer/Footer";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// // import { Carousel } from 'bootstrap';
// import  "../src/index.css";
// import Carousel from './Components/Carousel';
// import Header from './Components/Header/Header';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import About from './Components/About/About';
// import FAQs from "./Components/FAQs/FAQs";
// import Workwithus from './Components/WorkWithUs/Workwithus';
// import Newsblog from './Components/Newsblog/Newsblog';
// import GroundShippingBlog from './Components/GroundShippingBlog/GroundShippingBlog';
// import CustomCLerance from "./Components/CustomCLearance/CustomCLerance";
// import Warehousing from './Components/Warehousing/Warehousing';
// import InternationalTrade from "./Components/InternationalTrade/InternationalTrade";
// import Logistic from './Components/Logistic/Logistic';
// import Dangerousgoods from './Components/DangerousGoods/Dangerousgoods';
// import OurServices from './Components/OurServices/OurServices';
// // import CustomCLerance from "./Components/CustomCLearance/CustomCLerance";

// function App() {
//   return (
//     <div className="App">
//     <Router>
//       <Header />
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/faqs" element={<FAQs />} />
//         <Route path="/workwithus" element={<Workwithus />} />
//         <Route path="/Newsblog" element ={<Newsblog/>}/>
//       <  Route path="/GroundShippingBlog" element={<GroundShippingBlog/>}/>


//       <Route path="/OurServices" element={<OurServices/>}/>
  
//       <Route path="/Warehousing" element={<Warehousing/>}/>
//       <Route path="/InternationalTrade" element={<InternationalTrade/>}/>
//       <Route path="/CustomCLerance" element={<CustomCLerance/>}/>
//       <Route path="/Logistic" element={<Logistic/>}/>
//       <Route path = "/Dangerousgoods" element={<Dangerousgoods/>}/>
      
//       </Routes>
//       <Footer />
//     </Router>
//   </div>

//   );
// }

// export default App;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import Carousel from './Components/Carousel';
import Header from './Components/Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import About from './Components/About/About';
import FAQs from "./Components/FAQs/FAQs";
import Workwithus from './Components/WorkWithUs/Workwithus';
import Newsblog from './Components/Newsblog/Newsblog';
import GroundShippingBlog from './Components/GroundShippingBlog/GroundShippingBlog';
import CustomCLerance from "./Components/CustomCLearance/CustomCLerance";
import Warehousing from './Components/Warehousing/Warehousing';
import InternationalTrade from "./Components/InternationalTrade/InternationalTrade";
import Logistic from './Components/Logistic/Logistic';
import Dangerousgoods from './Components/DangerousGoods/Dangerousgoods';
import OurServices from './Components/OurServices/OurServices';

function App() {
  return (
    <div className="w-full h-screen App">
      <Router>
        {/* <HeaderToggle /> */}
        <Header/>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/workwithus" element={<Workwithus />} />
          <Route path="/Newsblog" element={<Newsblog />} />
          <Route path="/GroundShippingBlog" element={<GroundShippingBlog />} />
          <Route path="/OurServices" element={<OurServices />} />
          <Route path="/Warehousing" element={<Warehousing />} />
          <Route path="/InternationalTrade" element={<InternationalTrade />} />
          <Route path="/CustomCLerance" element={<CustomCLerance />} />
          <Route path="/Logistic" element={<Logistic />} />
          <Route path="/Dangerousgoods" element={<Dangerousgoods />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

// function HeaderToggle() {
//   const location = useLocation();
//   return location.pathname !== '/' ? <Header /> : null;
// }

export default App;

