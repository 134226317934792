import React from "react";
import warehouse from "../../Assets/whatware.jpg";
import international from "../../Assets/InternationalTrade.jpg";
import "./InternationalTrade.css";

const InternationalTrade = () => {
  return (
    <div>
      <div className="Ground-Shipping-Blog-div">
        <div className="Ground-Shipping-Img-div">
          <div className="Ground-shiping-img-div">  <img src={international} alt="MukulDbb"  style={{width: '825px', height: '510px'}} /></div>
          <div className="Ground-shipaing-all-content">

          <h1>Freight Forwarding: India and Thailand</h1>
          <p>
            In the last few years, international trade channels between India
            and Thailand have displayed massive growth. With a stunning exchange
            of billions of dollars per year between the two countries, the
            blooming relationship has turned out to be fruitful for both the
            governments and private parties involved. Let us get an overview of
            the trade relations between the two countries in recent years.
          </p>

          <h3
            style={{
              fontSize: "22px",
              color: "#4444444",
              marginBottom: "2rem",
            }}
          >
            India-Thailand Economic & Commercial Relations
          </h3>

          <h3
            style={{
              text: "bold",
              fontSize: "22px",
              marginBottom: "2rem",
              fontWeight: "600",
            }}
          >
            Overview
          </h3>
          <p>
            The main function of a warehouse is to store large quantities of
            goods, condense them into smaller quantities, and dispense them to
            the end customer. The chain of operations of a warehouse is
            receiving, putting away, storage, order preparation, and packing and
            shipping of goods
          </p>

          <ol>
            <li>
              Thailand is the second-largest economy in the Association of
              Southeast Asian Nations (ASEAN) and the Greater Mekong Subregion
              (GMS). It is an upper-middle- income country with a Gross Domestic
              Product (GDP) of US$ 504.9 billion in 2018.
            </li>
            <li>
              India’s economic and commercial relations with Thailand are rooted
              in history, age- old socio-cultural interactions, and extensive
              people-to-people contacts. India and Thailand celebrated 70 years
              of diplomatic relations in 2017. India’s ‘Look East’ policy (since
              1993) and Thailand’s ‘Look West’ policy (since 1996) which has now
              metamorphosed into India’s ‘Act East’ and Thailand’s ‘Act West”
              are strongly contributing to consolidating bilateral relations
              including economic & commercial linkages.
            </li>
            <li>
              Two-way trade in 2018 totaled US $ 12.46 billion, with about US
              $7.60 billion in Thai exports to India and the US $ 4.86 billion
              in Indian exports to Thailand. In the ASEAN region, Thailand ranks
              as India’s 5th largest trading partner after Singapore, Vietnam,
              Indonesia, and Malaysia. The growing ties between the two
              countries have come at a time when the AEC is expected to bring
              greater integration among member countries be it in the form of
              physical connectivity, economic links, or cultural and educational
              ties.
            </li>
            <li>
              The fast-growing Indian market remains attractive for Thai
              investors, given the vast opportunities available in the
              infrastructure sector, tourism, and retail industries. India
              continues to remain an interesting market for the export of goods
              from Thailand. Currently, Thai goods have benefited from tax
              reduction under ASEAN-India FTA in Goods, which came into effect
              on 01 January 2010 and resulted in the flow of more goods into the
              Indian market. An Early Harvest Scheme (EHS), covering 82 products
              (now 83 products) under the proposed India-Thailand FTA, in place
              since September 2004, has already resulted in phenomenal growth in
              our bilateral trade.
            </li>
          </ol>
          <br />

          <h3 style={{ text: "bold", fontSize: "20px", color: "#4444444" }}>
            Bilateral Trade
          </h3>

          <p>
            Thailand views India as the gateway to South Asia and beyond. As a
            result of the reduced tariff rates and new initiatives adopted by
            both countries, trade between the two countries has increased
            manifold in recent years. Bilateral Trade has multiplied eight times
            since 2000 to reach US $12.46 billion in 2018. Trade figures between
            the two countries for the last five years are as under:
          </p>
          <br />
          <p>
            {" "}
            The next thing to look out for is paperwork. Customs agents are
            extremely cautious when it comes to inspecting and verifying your
            paperwork. Your paperwork and documentation should be error- free
            with all necessary details mentioned, such as the content of the
            shipment and your business details. Here’s a list of the basic
            documents you should keep with you to ensure a hassle-free shipment:
          </p>
          <ul>
            <li>
              <span style={{ text: "bold", fontSize: "18px" }}>
                Receiving:{" "}
              </span>
              This starts with the warehouse being notified about the arrival of
              goods. This helps it plan the unloading and ensure the warehouse
              team can manage other processes in the warehouse accordingly.
            </li>
            <li>
              <span style={{ text: "bold", fontSize: "18px" }}>
                Putting Away:{" "}
              </span>
              Goods must be stored, but before that one has to decide a proper
              location for them. This process involves storage management of a
              secondary inventory where the product will eventually go.
            </li>
            <li>
              <span style={{ text: "bold", fontSize: "18px" }}>Storage: </span>
              Goods must be stored, but before that one has to decide a proper
              location for them. This process involves storage management of a
              secondary inventory where the product will eventually go.
            </li>
            <li>
              <span style={{ text: "bold", fontSize: "18px" }}>
                Order Preparation:{" "}
              </span>
              Also known as order picking, it is the procedure of collecting and
              preparing items before shipment to a consumer. During order
              preparation, it is essential for a warehouse to provide the
              necessary documentation and records pertaining to the shipment.
            </li>
            <li>
              <span style={{ text: "bold", fontSize: "18px" }}>
                Packing and Shipping:{" "}
              </span>{" "}
              Every customer order must be managed individually; due to this,
              packing is a labor-intensive process. On top of this, each order
              must be accurate and complete with regards to the needs of the
              customer. Shipping is a key aspect in warehousing, and is the
              final step of the process. Packing and shipping involves weighing
              of goods, maintaining shipment information, and labelling the
              final destination address.
            </li>
          </ul>
          <h3 style={{ text: "bold", fontSize: "20px", color: "#4444444" }}>
            Warehousing Operations
          </h3>
          <ul>
            <li>
              <span style={{ text: "bold", fontSize: "18px" }}>
                Private Warehouses:
              </span>
              Warehouses which are used by producers, traders, or manufacturers
              to store their own stock exclusively are known as private
              warehouses. These are typically located near a manufacturing
              facility and are maintained by individuals from the same facility.
              An example is a farmer building a warehouse near his field.
            </li>
            <li>
              <span style={{ text: "bold", fontSize: "18px" }}>
                Public Warehouses:{" "}
              </span>
              Warehouses which provide warehousing services and are available to
              rent by the public are known as public warehouses. These are
              usually used for the storage of smaller shipments, and are used
              when dealing with seasonal goods or when entering a new market.
            </li>
            <li>
              <span style={{ text: "bold", fontSize: "18px" }}>
                Contract Warehouses:{" "}
              </span>
              Warehouses which provide warehousing services on a contractual
              basis are known as contract warehouses. These contracts are
              generally long term between the two parties (leaser and
              contractor) and are mostly used when a business is newly
              established, or when a business is expanding and entering a new
              market. Such warehouses minimize liability and personal risk, as
              well as saving a pretty penny.
            </li>
          </ul>
          <span>(Amount in Billion USD)</span>
          <table className="trade-data">
            <thead></thead>
            <tbody>
              <tr>
                <th>Year </th>
                <th>Total trade </th>
                <th>Total Export to India</th>
                <th>Total Import from India </th>
              </tr>
              <tr>
                <td>2014</td>
                <td>8.65 </td>
                <td>5.61 </td>
                <td>3.04 </td>
              </tr>
              <tr>
                <td>2015</td>
                <td>7.92 </td>
                <td>5.29 </td>
                <td>2.63 </td>
              </tr>
              <tr>
                <td>2016 </td>
                <td>7.72 </td>
                <td>5.15 </td>
                <td>2.57 </td>
              </tr>
              <tr>
                <td>2017 </td>
                <td>10.39 </td>
                <td>6.49 </td>
                <td>3.9</td>
              </tr>
              <tr>
                <td>2018</td>
                <td>12.46 </td>
                <td>7.6 </td>
                <td>4.86 </td>
              </tr>
            </tbody>
          </table>
          <p>
            <span style={{ text: "bold", fontSize: "18px" }}>
              Private Warehouses:
            </span>
            Warehouses which are used by producers, traders, or manufacturers to
            store their own stock exclusively are known as private warehouses.
            These are typically located near a manufacturing facility and are
            maintained by individuals from the same facility. An example is a
            farmer building a warehouse near his field.
          </p>
          <p>
            <span style={{ text: "bold", fontSize: "18px" }}>
              Major Thai Imports:
            </span>
            Crude Oil, machinery & parts, electrical machinery & parts,
            chemicals, jewelry including silver bars & gold, Iron, steel and
            products, parts & accessories of vehicles, Electronic integrated
            circuits, Other metal ores, metal waste scrap and products,
            computers, parts and accessories, electrical house-hold appliances,
            vegetables and vegetable products, airplanes, gliders, instruments,
            and parts, finished oils, Plastic products, etc.
          </p>
          <p>
            DBB Worldwide believes in providing a single-stop solution to all
            its clients despite their location of origin, nature of goods they
            deal in, the volume of goods, or any special requirements by the
            clients. Providing a customized solution for every supply-chain-
            related service required by a client, be it transportation to the
            storage facilities, warehousing of different types of products,
            international import/export of those products, international custom
            clearances, documentation, etc.
          </p>
          <h3>Investments</h3>
          <span>
            Approved Indian investment in Thailand during the last few years is
            as under:{" "}
          </span>
          <table className="trade-data">
            <thead></thead>
            <tbody>
              <tr>
                <th>Year </th>
                <th>Investment (million USD) </th>
              </tr>
              <tr>
                <td>2014</td>
                <td>63.45</td>
              </tr>
              <tr>
                <td>2015</td>
                <td>37.53</td>
              </tr>
              <tr>
                <td>2016</td>
                <td>32.66</td>
              </tr>
              <tr>
                <td>2017</td>
                <td>32.17</td>
              </tr>
              <tr>
                <td>2018</td>
                <td>13.34</td>
              </tr>
            </tbody>
          </table>

          <p>
            Investment from Thailand in India has increased in recent years.
            Thai investments are mainly in infrastructure, real estate, food
            processing sectors, chemicals, hotels, and the hospitality sector.
            The break-up of yearly FDI from Thailand to India in the previous
            years is as follows:{" "}
          </p>
          <table className="trade-data">
            <thead></thead>
            <tbody>
              <tr>
                <th>Year </th>
                <th>FDI inflow (in million USD) </th>
              </tr>
              <tr>
                <td>2012</td>
                <td>11.55</td>
              </tr>
              <tr>
                <td>2013</td>
                <td>60.89</td>
              </tr>
              <tr>
                <td>2014</td>
                <td>23.72</td>
              </tr>
              <tr>
                <td>2015</td>
                <td>24.3</td>
              </tr>
              <tr>
                <td>2016</td>
                <td>68.87</td>
              </tr>
              <tr>
                <td>2017</td>
                <td>81.76</td>
              </tr>
              <tr>
                <td>2018</td>
                <td>92.22</td>
              </tr>
            </tbody>
          </table>
          <p>
            Major Indian Companies in Thailand include Tata group – Tata Motors
            (Thailand), Tata Steel Thailand and Tata Consultancy Services, The
            Aditya Birla Group, Mahindra Satyam, Ranbaxy, Dabur, Lupin, NIIT,
            Kirloskar Brothers Ltd, Punj Lloyd Group, Polyplex (Thailand) Public
            Co. Ltd, Precious Shipping PCL, and Usha Siam Steel Industries PCL.
            It is known that companies like Jindal group [steel], Ashok Leylands
            [automobiles], Mahindras [automobiles], Escorts, D.R. Hotels
            (Nellore) Pvt. Ltd., and D.R. Utthama (Thailand) Co. Ltd., are also
            keen to expand their operations into Thailand.Major Indian Companies
            in Thailand include Tata group – Tata Motors (Thailand), Tata Steel
            Thailand and Tata Consultancy Services, The Aditya Birla Group,
            Mahindra Satyam, Ranbaxy, Dabur, Lupin, NIIT, Kirloskar Brothers
            Ltd, Punj Lloyd Group, Polyplex (Thailand) Public Co. Ltd, Precious
            Shipping PCL, and Usha Siam Steel Industries PCL. It is known that
            companies like Jindal group [steel], Ashok Leylands [automobiles],
            Mahindras [automobiles], Escorts, D.R. Hotels (Nellore) Pvt. Ltd.,
            and D.R. Utthama (Thailand) Co. Ltd., are also keen to expand their
            operations into Thailand.
          </p>
          <p>
            Leading Thai companies in the fields of Agro-processing,
            construction, automotive, engineering, and banking have an active
            and growing business presence in India. Major Thai companies in
            India are –
          </p>

          <p>
            There is an active India-Thai Joint Commission (headed by respective
            Foreign Ministers), a well-established India-Thai Chamber of
            Commerce and an active India-Thai Business Association. A Joint
            Business Council has been set up since May 2013 with the involvement
            of the private sector to stimulate the expansion of bilateral trade
            and investments and facilitate business partnerships.
          </p>
          <p>
            Air connectivity between India and Thailand is growing with 330
            flights per week reflecting a rapidly growing traffic of tourists
            and business people between the two countries. All major airlines of
            India & Thailand operate between the two countries: Air India, Jet
            Airways, Spicejet, Thai Airways, Thai Smile Airway, Bangkok Airways,
            Thai Air Asia, Go Air, Nok Scoot, Thai Lion Air, and IndiGo.
          </p>

          <h3>Major Seaports in India</h3>
          <ul>
            <li>Nhava Sheva Port</li>
            <li>Port of Mundra</li>
            <li>Chennai Port</li>
            <li>Kolkata Port</li>
            <li>Mumbai Port</li>
          </ul>

          <h3>Major Airports in Thailand</h3>
          <ul>
            <li>Suvarnabhumi Airport, Bangkok and Samut Prakan</li>
            <li>
              Don Mueang International Airport, Bangkok, Nonthaburi and Prakan
            </li>
            <li>Chiang Mai International Airport, Chiang Mai and Lamphun</li>
            <li>Phuket International Airport, Phuket</li>
            <li>Hat Yai International Airport, Hat Yai and Songkhla</li>
            <li>Mae Fah Luang-Chiang Rai International Airport, Chiang Rai</li>
          </ul>

          <h3>Major Seaports in India</h3>
          <ul>
            <li>Nhava Sheva Port</li>
            <li>Port of Mundra</li>
            <li>Chennai Port</li>
            <li>Kolkata Port</li>
            <li>Mumbai Port</li>
          </ul>

          <h3>Major Seaports in India</h3>
          <ul>
            <li>Nhava Sheva Port</li>
            <li>Port of Mundra</li>
            <li>Chennai Port</li>
            <li>Kolkata Port</li>
            <li>Mumbai Port</li>
          </ul>

          <p>
            DBB Worldwide offers connectivity to all the major sea and airports
            in India and Thailand. This makes all the clients engage and expand
            operation in import and export of goods at a mass level via their
            choice of transportation, place of origin, and destination. Hence,
            if you are looking to expand your business in Thailand, you will not
            regret connecting with us.
          </p>

          <p>
            With such booming industries and opportunities on both sides of
            borders, this is a good time to expand your business to the Thai
            market. The best part is, with so many freight forwarding experts in
            the market, you do not have to worry about how you will export or
            import goods to/from Thailand. Everything is available at the tap of
            a finger.
          </p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default InternationalTrade;
