import React from "react";
import "./Workwithus.css";
import phone from "../../Assets/circle_14025200.png";
import location from "../../Assets/location.png";
import chat from "../../Assets/chat_9931619.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Workwithus = () => {
  const pathStyles = {
    transform: 'scalex(2.5)',
  };
  const svgStyles = {
    transform: 'rotate(180deg)',
    width: '100%',
  };
  return (

    <div>

      <div className="curved-bottom workwithus-homep">
        <div className="Faq-content">
          <h1>Work With Us</h1>
          <h3>Join Hands With Us</h3>
        </div>
        <div className="absolute bottom-0 left-0 right-0" style={svgStyles}>
          <svg style={{ marginTop: '-1px' }}
            viewBox="0 0 1440 320"
            preserveAspectRatio="none"
            className="w-full"
          >
            <path style={pathStyles}
              fill="#fff" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
            ></path>
          </svg>
        </div>

      </div>

      <div className="work-with-us-container work-with-us-container-M">
        <div>

          <h2>
            We Would Be Happy To Meet You And Learn All About Your Business
          </h2>
        </div>



        <div className="work-heading">
          <h1>Let's Meet</h1>
          <h3>Coffee Is On Us</h3>
        </div>

        <div className="work-with-us-inner-container-wrapper">
          <div className="work-with-us-inner-container">
            <img
              src={phone}
              alt="Customer Satisfaction"
            />
            <h3>Greater Customer Satisfaction</h3>
            <p>+91-8929919292</p>
          </div>




          <div className="work-with-us-inner-container">
            <img
              src={location}
              alt="We're On The Map"
            />
            <h3>We're On The Map</h3>
            <p>2nd Floor, Plot No, 269, Phase IV, Udyog Vihar, Sector 18, Gurugram, Haryana 122015</p>
          </div>
          <div className="work-with-us-inner-container">
            <img
              src={chat}
              alt="Send Us A Message"
            />
            <h3>Send Us A Message</h3>
            <p>enquiry@dbbworldwide.com</p>
          </div>
        </div>

      </div>

    </div>
  );
};

export default Workwithus;
