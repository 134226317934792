

import React from "react";
import { Link } from 'react-router-dom';
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="Footer-contain container">
        <div className="row">
          <div className="footer-div">
            <h5>Company</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/OurServices">Our Services</Link>
              </li>
              <li>
                <Link to="/faqs">FAQ</Link>
              </li>
              <li>
                <Link to="/Newsblog">News & Blogs</Link>
              </li>
              <li>
                <Link to="/workwithus">Work With us</Link>
              </li>
            </ul>
          </div>

          <div className="footer-div">
            <h4>Contact Us</h4>
            <ul className="list-unstyled">
              <li>
                <a href="tel:+918929919292">+91-8929919292</a>
              </li>
              <li>
                <a href="mailto:enquiry@dbbworldwide.com">enquiry@dbbworldwide.com</a>
              </li>
              <li  style={{ fontFamily: 'Montserrat, sans-serif', fontSize:"15px" }}>
                2nd Floor, Plot No, 269, Phase IV,
                <br />
                Udyog Vihar, Sector 18, Gurugram,
                <br />
                Haryana 122015
              </li>
            </ul>
          </div>

          <div className="footer-div">
            <h5>Our Brands</h5>
            <ul className="list-unstyled">
              <li><a href="https://carrymypet.com/">Carrymypet</a></li>
              <li><a href="https://carrymybaggage.com/">Carrymybaggage</a></li>
              <li>
                <button className="btn btn-primary">Get Quote</button>
              </li>
            </ul>
          </div>

          <div className="footer-div download-brochure">
            <h5>Download Brochure</h5>
            <button className="btn btn-primary">
              Download <i className="fas fa-download"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="">
          <p>© DBB Worldwide Pvt Ltd.</p>
          <div className="social-icons">
            <a href="https://linkedin.com">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://facebook.com">
              <i className="fab fa-facebook"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

