import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo from '../../Assets/logo.png' 
// Ensure the correct path to your logo
import "./Header.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"; // Import the arrow up icon


const Header = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [navVisible, setNavVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleNav = () => {
    setNavVisible(!navVisible);
  };

  const dropdownRef = useRef(null); // Create a reference for the dropdown


 // Close the dropdown if clicked outside
 useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false); // Close dropdown when clicking outside
    }
  };

  // Lock the body scroll when nav is visible
  if (navVisible) {
    document.body.style.overflow = "hidden"; // Disable scrolling
  } else {
    document.body.style.overflow = ""; // Reset to default behavior
  }

  // Event listener for clicking outside of the dropdown
  document.addEventListener("mousedown", handleClickOutside);

  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
    document.body.style.overflow = ""; // Reset on cleanup
  };
}, [navVisible]); // This effect runs whenever `navVisible` changes




  const styles = `
  
/* Laptop view */

.header_container {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
}

.header {
  // max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 150px;
}

.nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-list li {
  margin-left: 20px;
}

.nav-list li a {
  text-decoration: none;
  color: white;
  font-weight: 500;
}

.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: -67px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  list-style: none;
  margin: 0;
}

.dropdown-menu li a {
  display: block;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
}

.dropdown.active .dropdown-menu {
  display: block;
}

.nav-toggle {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

@media (max-width: 768px) {

.header .logo-container{
 //flex: 1;   
  display: flex;
  align-items: center;
 
}
  .nav-list-container {
    width: 100%;
    display: none;
    flex-direction: column;
  }

  .nav-list-container.active {
    display: flex;
  }

  .nav-list li {
    // margin: 10px 0;
    padding: 9px;
  }

  .nav-toggle {
    display: block;
    
  }
        .nav-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
   
}
    .dropdown-menu a:hover {
  background-color: blue;
}

    /* Mobile View */
    @media (max-width: 768px) {
      .nav-list-container {
       position: fixed;
        top: 64px;
        left: 0;
        height: 100vh;
        width: 250px;
        background-color: #333;
        padding: 20px;
        z-index: 100;
        transform: translateX(-100%); /* Initially off-screen on the left */
        transition: transform 0.4s ease-in-out; /* Smooth transition */
      }

      .nav-list-container.active {
        transform: translateX(0); /* Slide in from left to right */
        display:block
      }

      .nav-list-container{
      
      background: #1964f1;
      }

      .nav-list {
        flex-direction: column;
        gap: 15px;
        list-style: none;
      }

      .dropdown-menu {
        position: static;
      }

      .nav-toggle {
        display: block;
        color: white;
      }


}
`;


  return (
    <div className="header_container">
    <style>{styles}</style>
    <header className="header">
      <Link to="/" className="logo-container">
        <img className="logo" src={logo} alt="Logo" />
      </Link>
      <div className="nav-toggle" onClick={toggleNav}>
        &#9776; {/* Hamburger icon */}
      </div>
      <nav className={`nav-list-container ${navVisible ? "active" : ""}`}>
        <ul className="nav-list">
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/OurServices">Our Services</Link>
          </li>
          <li>
            <Link to="/faqs">FAQs</Link>
          </li>
          <li>
            <Link to="/Newsblog">News & Blog</Link>
          </li>
          <li>
            <Link to="/workwithus">Work With Us</Link>
          </li>
          <li
              className={`dropdown ${dropdownVisible ? "active" : ""}`}
              onClick={toggleDropdown}
              ref={dropdownRef}  // Ref for the dropdown
            >
            Our Brands  {dropdownVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            <ul className={`dropdown-menu ${dropdownVisible ? "active" : ""}`}>
              <li>
                <a href="https://carrymypet.com/" target="_blank">Carrymypet</a>
              </li>
              <li>
                <a href="https://carrymybaggage.com/" target="_blank">Carrymybaggage</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  </div>
  );
};

export default Header;
