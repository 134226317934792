import React from "react";
import "./Newsblog.css";
import work from "../../Assets/work.jpg";
import cust from "../../Assets/cust.jpg";
import forwd from "../../Assets/forwd.jpg";
import thai from "../../Assets/thai.jpg";
import log from "../../Assets/log.jpg";
import danger from "../../Assets/danger.jpg";
import { useNavigate } from "react-router-dom"; 


const Newsblog = () => {
  const pathStyles = {
    transform: 'scalex(2.5)',
  };
  const svgStyles = {
    transform: 'rotate(180deg)',
      width: '100%',
  };
  const navigate = useNavigate(); 

  const handleNavigation = (path) => {
    navigate(path); 
  };
  return (
    <div>
      <div className="curved-bottom newsblogs-homep">
        <div className="Faq-content">
          <h1>news & blogs</h1>
          <h3>behind the scenes at beyond</h3>
        </div>
        <div className="absolute bottom-0 left-0 right-0" style={svgStyles}>
              <svg style={{marginTop: '-1px'}}
                viewBox="0 0 1440 320"
                preserveAspectRatio="none"
                className="w-full"
              >
                <path style={pathStyles}
                  fill="#fff" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
                  ></path>
              </svg>
            </div>
      </div>
      <div className="row News-div-container News-div-container-M">
      <div className="col-md-3">
        <div className="news-box">
          <img src={work} />
          <h3> Ground Shipping in Freight Forwarding and How Does it Work? </h3>
          <p>
            Shipping refers to the movement of goods and products from one
            location to the next. The shipping process comes after
          </p>

          <button onClick={() => handleNavigation("/GroundShippingBlog")}>
            Learn More
          </button>
        </div>
        </div>
              <div className="col-md-3">
        <div className="news-box">
          <img src={cust} />
          <h3> Freight Forwarding Customs Clearance and How Does it Work? </h3>
          <p>
            To understand customs clearance in freight forwarding, it is
            important to understand freight forwarding first. Most importers and
            exporters use
          </p>
          <button onClick={() => handleNavigation("/CustomCLerance")}>
            Learn More
          </button>
        </div>
        </div>
        <div className="col-md-3">
        <div className="news-box">
          <img src={forwd} />

          <h3> Freight Forwarding Warehousing and How Does it Work? </h3>
          <p>
            Warehouses are an essential component in the chain of distribution
            logistics. A warehouse is any building where products, materials, or
          </p>
          <button onClick={() => handleNavigation("/Warehousing")}>
            Learn More
          </button>
        </div>
        </div>
      </div>

      <div className="row News-div-container News-div-container-M">
      <div className="col-md-3">
      <div className="news-box">
          <img src={thai} />

          <h3> Freight Forwarding: India and Thailand </h3>
          <p>
            In the last few years, international trade channels between India
            and Thailand have displayed massive growth. With a stunning exchange
          </p>

          <button onClick={() => handleNavigation("/InternationalTrade")}>
            Learn More
          </button>
        </div>
      </div>
      <div className="col-md-3">
        <div className="news-box">
          <img src={log} />
          <h3> What is End to End Logistics & Supply Chain Solutions? </h3>
          <p>
            What Is End-to-End? End-to-end refers to an interaction that takes a
            framework or administration from start to finish and conveys
          </p>
          <button onClick={() => handleNavigation("/Logistic")}>
            Learn More
          </button>
        </div>
        </div>
        <div className="col-md-3">
        <div className="news-box">
          <img src={danger} />
          <h3> How to Transport Dangerous Goods? </h3>
          <p>
            What are dangerous goods? Dangerous Goods are items that may
            endanger the safety of an aircraft or persons on board
          </p>
          <button onClick={() => handleNavigation("/Dangerousgoods")}>
            Learn More
          </button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Newsblog;
